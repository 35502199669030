<!-- AI切片 -->
<template>
  <div class="aiSlicing">
    <div class="slicingContent">
      <div
        class="slicingFrom"
        v-if="!isViewDetail"
      >
        <div class="slicingFromTitle">{{ $t('aiSlicing.contentTitle') }}</div>
        <div class="slicingFromBox">
          <el-input
            class="inputStyle"
            :placeholder="$t('videoCarry.pleaseVideoLink')"
            v-model="videoUrl"
            @change="changeInput"
          >
            <i
              slot="prefix"
              :class="['iconfont', iconType === 'loca' ? 'icon-shangchuan' : iconType === 'material' ? 'icon-sucaikubeifen' :'icon-lianjiebeidianjiliang']"
            ></i>
            <div
              v-if="videoUrl"
              slot="suffix"
              class="delBtn"
              @click="deleteVideo"
            >{{ $t('aiSlicing.delete') }}</div>
          </el-input>
          <slicingUpload
            ref="slicingUpload"
            v-show="!uploadBoxLoading"
            :fileType="fileType"
            :initNum="6"
            @selectMaterial="selectMaterial"
            @showLocaMedia="showLocaMedia"
            @uploading="uploading"
          />
          <div
            class="uploadLoading"
            v-show="uploadBoxLoading"
          >
            <div class="leftLoading">
              <img
                class="loadingPic"
                src="@/assets/images/loadingGif.gif"
                alt=""
              >
              {{ $t('aiSlicing.uploadingMaterial') }}...
            </div>
            <div
              class="cancelUpload"
              @click="cancelUpload"
            >{{ $t('aiSlicing.cancel') }}</div>
          </div>
          <el-button
            :disabled="uploadBoxLoading"
            type="primary"
            :class="['gradient-button', uploadBoxLoading && 'loadingBtn']"
            @click="startSlicing"
          >{{ $t('aiSlicing.startSlicing') }}</el-button>
          <div
            class="tip"
            v-if="!videoUrl"
          >{{ $t('aiSlicing.uploadTip') }}</div>
          <div
            class="saveMaterialBox"
            v-else
          >
            <div class="saveMaterialTitle">
              {{ $t('aiSlicing.saveTitle') }}：
            </div>
            <treeselect
              v-model="materialTypeId"
              :options="treeFileList"
              :show-count="false"
              :normalizer="normalizer"
              :clearable="false"
              :load-options="loadOptions"
              :placeholder="$t('aiSlicing.saveLocation')"
              :disabled="false"
            >
              <template #value-label="{ node }">
                {{ node.raw.name ? node.raw.name : $t('aiSlicing.saveLocation') }}
              </template>
            </treeselect>
          </div>
        </div>
        <div
          class="viewUploadVideo"
          v-if="videoItem.url"
        >
          <video
            controls
            :src="videoItem.url"
          ></video>
        </div>
        <div
          class="historyBox"
          v-loading="loading"
        >
          <div
            class="videoItem"
            v-for="item in taskList"
            :key="item.id"
          >
            <div class="material-container">
              <video
                :src="item.sourceUrl"
                class="videoBox"
              ></video>
              <div class="userInfo">
                <div
                  class="leftTime"
                  v-if="item.duration"
                >
                  {{ item.duration }}
                </div>
                <div
                  class="rightSize"
                  v-if="item.fileSize"
                >
                  {{ formatFileSize(item.fileSize) }}
                </div>
              </div>
              <div class="stateBox">
                {{ filterStatus(item.jobStatus) }}
              </div>
            </div>
            <div class="videoTitle">
              {{ item.videoName }}
            </div>
            <div class="videoBtnBox">
              <div class="creatTime">{{  filterTime(item.createTime)  }}</div>
              <div
                class="detailBtn"
                @click="viewDetail(item)"
              >{{ $t('aiSlicing.checkTheDetails') }}</div>
            </div>
          </div>
        </div>
      </div>
      <aiSlicingDetail
        v-else
        :detailIds="detailIds"
        :folderId="folderId"
        @goBackSlicing="goBackSlicing"
      />
    </div>
  </div>
</template>

<script>
import slicingUpload from "./components/slicingUpload.vue";
import aiSlicingDetail from "./components/aiSlicingDetail.vue";
import Treeselect from "@riophae/vue-treeselect";
import { listMaterialType } from "@/api/ai/materialType";
import { getTaskList } from "@/api/ai/aiFacewarp";
import { addSplitVideo } from "@/api/ai/aiSlicing";
import { formatFileSize } from "@/utils/index";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "AiSlicing",
  components: { slicingUpload, Treeselect, aiSlicingDetail },
  data() {
    return {
      quota: {
        usePublishCount: 0,
        publishCount: 100,
      },
      uploadBoxLoading: false,
      loading: false,
      fileType: ["mp4", "avi", "mkv", "mov"],
      videoUrl: "",
      videoItem: {},
      treeFileList: [],
      materialTypeId: "",
      iconType: "",
      timer: null,
      isViewDetail: false,
      folderId: "",
      taskList: [],
      isUrl: true,
      detailIds: [],
      // detailList: [
      //   {
      //     title: '光厂_8044194_8K螺丝螺帽自动分1',
      //     describe: '  论具维争经治象自由议式方一员道布平给单织角验车强到种量资。细用极或因拉般养去众自算你名组铁论时日专老委命该飞至节是民利给价九天运却现状都史史然却称面走油标他情团务号亲研白间。效千算在那传重完近共张却议造进除大大验往具安思内例资叫时数认成百发目或世员他量及。从入将比断林金写党着况也明天性济此少验北金接精别合共内王具究知也报公任严民变层小圆现到式开而情历律员现很音毛而了它管解资青速要提。易论更日严前总王个根维整法会结话那省验争加民性行种并素影率社下增构列但别导音头识后主照成什组程知基层可确四相个清工地历并边六划基土科分处联。',
      //     url: 'https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/file/2024/11/14/ZbDXgo6qIVb96HtdkurtgccgNL2jwb.mp4',
      //     tags: ['电脑产品', 'No surprise 原则', '标签3'],
      //     isEditTitle: false,
      //     editTitleVal: '',
      //     isEditDescribe: false,
      //     editDescribeVal: '',
      //     isEditTags: false,
      //     selects: [
      //       { selectedTag: "" }
      //     ],
      //   },
      //   {
      //     title: '光厂_8044194_8K螺丝螺帽自动分2',
      //     describe: '  论具维争经治象自由议式方一员道布平给单织角验车强到种量资。细用极或因拉般养去众自算你名组铁论时日专老委命该飞至节是民利给价九天运却现状都史史然却称面走油标他情团务号亲研白间。效千算在那传重完近共张却议造进除大大验往具安思内例资叫时数认成百发目或世员他量及。从入将比断林金写党着况也明天性济此少验北金接精别合共内王具究知也报公任严民变层小圆现到式开而情历律员现很音毛而了它管解资青速要提。易论更日严前总王个根维整法会结话那省验争加民性行种并素影率社下增构列但别导音头识后主照成什组程知基层可确四相个清工地历并边六划基土科分处联。',
      //     url: 'https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/file/2024/11/14/ZbDXgo6qIVb96HtdkurtgccgNL2jwb.mp4',
      //     tags: ['标签1', '标签2', '标签3'],
      //     isEditTitle: false,
      //     editTitleVal: '',
      //     isEditDescribe: false,
      //     editDescribeVal: '',
      //     isEditTags: false,
      //     selects: [
      //       { selectedTag: "" }
      //     ],
      //   },
      //   {
      //     title: '光厂_8044194_8K螺丝螺帽自动分3',
      //     describe: '  论具维争经治象自由议式方一员道布平给单织角验车强到种量资。细用极或因拉般养去众自算你名组铁论时日专老委命该飞至节是民利给价九天运却现状都史史然却称面走油标他情团务号亲研白间。效千算在那传重完近共张却议造进除大大验往具安思内例资叫时数认成百发目或世员他量及。从入将比断林金写党着况也明天性济此少验北金接精别合共内王具究知也报公任严民变层小圆现到式开而情历律员现很音毛而了它管解资青速要提。易论更日严前总王个根维整法会结话那省验争加民性行种并素影率社下增构列但别导音头识后主照成什组程知基层可确四相个清工地历并边六划基土科分处联。',
      //     url: 'https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/file/2024/11/14/ZbDXgo6qIVb96HtdkurtgccgNL2jwb.mp4',
      //     tags: ['标签1', '标签2', '标签3'],
      //     isEditTitle: false,
      //     editTitleVal: '',
      //     isEditDescribe: false,
      //     editDescribeVal: '',
      //     isEditTags: false,
      //     selects: [
      //       { selectedTag: "" }
      //     ],
      //   },
      //   {
      //     title: '光厂_8044194_8K螺丝螺帽自动分4',
      //     describe: '  论具维争经治象自由议式方一员道布平给单织角验车强到种量资。细用极或因拉般养去众自算你名组铁论时日专老委命该飞至节是民利给价九天运却现状都史史然却称面走油标他情团务号亲研白间。效千算在那传重完近共张却议造进除大大验往具安思内例资叫时数认成百发目或世员他量及。从入将比断林金写党着况也明天性济此少验北金接精别合共内王具究知也报公任严民变层小圆现到式开而情历律员现很音毛而了它管解资青速要提。易论更日严前总王个根维整法会结话那省验争加民性行种并素影率社下增构列但别导音头识后主照成什组程知基层可确四相个清工地历并边六划基土科分处联。',
      //     url: 'https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/file/2024/11/14/ZbDXgo6qIVb96HtdkurtgccgNL2jwb.mp4',
      //     tags: ['标签1', '标签2', '标签3'],
      //     isEditTitle: false,
      //     editTitleVal: '',
      //     isEditDescribe: false,
      //     editDescribeVal: '',
      //     isEditTags: false,
      //     selects: [
      //       { selectedTag: "" }
      //     ],
      //   }
      // ]
    };
  },
  created() {
    this.getFolder();
    this.getTaskList();
  },
  methods: {
    filterStatus(status) {
      if (status === "Executing") {
        return this.$t("aiSlicing.slicePendding");
      } else if (status === "Finished") {
        return this.$t("aiSlicing.sliceSuccess");
      } else if (status === "Failed") {
        return this.$t("aiSlicing.sliceError");
      }
    },
    goBackSlicing() {
      this.isViewDetail = false;
      this.detailList = [];
    },
    viewDetail(item) {
      const { folderId, result } = item;
      if (result && JSON.parse(result).length > 0) {
        console.log(JSON.parse(result));
        this.detailIds = JSON.parse(result);
        this.folderId = folderId;
        this.isViewDetail = true;
      } else {
        this.$message({
          message: this.$t("aiSlicing.noData"),
          type: "warning",
        });
      }
    },
    filterTime(time) {
      const datePart = time.split(" ")[0];
      return datePart.replace(/-/g, "-");
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    async getTaskList() {
      try {
        this.loading = true;
        const data = await getTaskList({ jobType: "split" });
        this.taskList = data.rows || [];
        if (this.taskList.some((item) => item.jobStatus == "Executing")) {
          if (!this.timer) {
            this.timer = setInterval(() => {
              this.getTaskList();
            }, 30000);
          }
        } else {
          this.clearTimer();
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      const countLabel =
        node.materialCount > 0 ? `  (${node.materialCount})` : "";
      return {
        id: node.id,
        label: `${node.name}${countLabel}`,
        children: node.children,
      };
    },
    loadOptions(node) {
      if (!node || !node.id) {
        node.callback([]);
        return;
      }
      node.callback([]);
    },
    getFolder() {
      listMaterialType().then((response) => {
        this.treeFileList = JSON.parse(JSON.stringify(response.data));
        this.treeFileList.unshift({
          id: 0,
          name: this.$t("basicInfo.materia"),
          type: "system",
        });
      });
    },
    deleteVideo() {
      this.videoUrl = "";
      this.videoItem.url = "";
      this.isUrl = true;
    },
    async startSlicing() {
      try {
        if (
          (this.videoUrl && this.materialTypeId) ||
          this.materialTypeId === 0
        ) {
          this.loading = true;
          let data = {};
          if (this.isUrl) {
            data = {
              url: this.videoUrl,
              saveTo: "material",
              folderId: this.materialTypeId,
            };
            // console.log({url: this.videoUrl, saveTo: 'material', folderId: this.materialTypeId});
          } else {
            data = {
              url: this.videoItem.url,
              name: this.videoUrl,
              saveTo: "material",
              folderId: this.materialTypeId,
            };
            // console.log({name: this.videoUrl, saveTo: 'material', folderId: this.materialTypeId});
          }
          await addSplitVideo(data);
          this.deleteVideo();
          this.materialTypeId = "";
          this.getTaskList();
          this.loading = false;
          console.log(data);
        } else {
          this.$message.warning(this.$t("aiSlicing.warningTip"));
          return;
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    cancelUpload() {
      if (this.$refs.slicingUpload) {
        this.$refs.slicingUpload.cancelUpload();
      }
    },
    uploading(info) {
      this.uploadBoxLoading = info;
    },
    changeInput() {
      this.isUrl = true;
    },
    showLocaMedia(items) {
      console.log(items);
      this.deleteVideo();
      this.isUrl = false;
      this.iconType = "loca";
      this.videoUrl = items[0].defaultName;
      this.videoItem = items[0];
      // items.forEach((item) => {
      //   this.selectVideoList.push(item);
      // });
    },
    selectMaterial(items) {
      console.log(items);
      this.deleteVideo();
      this.isUrl = false;
      this.iconType = "material";
      this.videoUrl = `${items[0].name}.${items[0].suffix}`;
      this.videoItem = items[0];
      // items.forEach((item) => {
      //   this.selectVideoList.push(item);
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.aiSlicing {
  background: white;
  height: 100%;

  .slicingContent {
    height: calc(100vh - 100px);
    background: #ffffff;
    overflow-y: auto;
    .slicingFrom {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .slicingFromTitle {
        // width: 119px;s
        height: 33px;
        font-family: PingFang-SC, PingFang-SC;
        font-weight: 500;
        font-size: 24px;
        color: #125ec3;
        line-height: 33px;
        text-align: left;
        font-style: normal;
        margin-top: 40px;
      }
      .slicingFromBox {
        margin-top: 24px;
        width: 600px;
        height: 270px;
        background: #f6f8fa;
        border-radius: 12px;
        border: 1px solid rgba(179, 179, 179, 0.38);
        padding: 32px 24px;
        box-sizing: border-box;
        .inputStyle {
          height: 48px;
          position: relative;
          ::v-deep .el-input__inner {
            background: #ffffff;
            border: 1px solid #cfd6e4;
            border-radius: 12px;
            color: #606266;
            height: 48px;
            line-height: 48px;
            padding-right: 55px !important;
          }
          ::v-deep .el-input__prefix {
            position: absolute;
            height: 100%;
            left: 10px;
            top: 16px;
            text-align: center;
            color: #c0c4cc;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
          }
          .delBtn {
            width: 35px;
            position: absolute;
            right: 10px;
            top: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #0156ff;
            line-height: 20px;
            text-align: left;
            font-style: normal;
            text-decoration-line: underline;
            cursor: pointer;
          }
        }
        .gradient-button {
          margin-top: 22px;
          width: 100%;
          height: 48px;
          background: linear-gradient(90deg, #0090ff 0%, #a200ff 100%);
        }
        .loadingBtn {
          opacity: 0.5;
        }
        .tip {
          margin-top: 24px;
          height: 22px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.4);
          line-height: 22px;
          text-align: center;
          font-style: normal;
        }
        .uploadLoading {
          // width: 84px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 22px;
          .leftLoading {
            height: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #0156ff;
            line-height: 20px;
            text-align: left;
            font-style: normal;
            display: flex;
            align-items: center;
            .loadingPic {
              width: 25px;
              height: 25px;
            }
          }
          .cancelUpload {
            height: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
            line-height: 20px;
            text-align: left;
            font-style: normal;
            cursor: pointer;
          }
        }
        .saveMaterialBox {
          margin-top: 16px;
          display: flex;
          align-items: center;
          .saveMaterialTitle {
            width: 112px;
            height: 22px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
            line-height: 22px;
            text-align: center;
            font-style: normal;
          }
          .vue-treeselect__control {
            padding-left: 5px;
            padding-right: 5px;
            display: table;
            table-layout: fixed;
            width: auto;
            height: 36px;
            border: none;
            border-radius: 5px;
            background: none;
          }
        }
      }
      .viewUploadVideo {
        margin-top: 16px;
        width: 600px;
        height: 222px;
        background: #f6f8fa;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        video {
          // width: 136px;
          height: 220px;
        }
      }
      .historyBox {
        margin-top: 166px;
        display: flex;
        flex-wrap: wrap;
        gap: 37px;
        .videoItem {
          width: 240px;
          height: 320px;
          background: #ffffff;
          border-radius: 12px;
          border: 1px solid #f8f8f8;
          padding: 10px;
          .material-container {
            width: 220px;
            height: 220px;
            position: relative;
            .videoBox {
              width: 220px;
              height: 220px;
              border-radius: 12px;
              background: #000000;
            }
            .cricle {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              display: flex;
              justify-content: center;
              align-items: center;
              width: 54px;
              height: 54px;
              border-radius: 50%;
              background: rgba(255, 255, 255, 0.5);
              cursor: pointer;
              .el-icon-caret-right {
                color: #fff;
                font-size: 35px;
              }
            }
            .userInfo {
              width: 204px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              position: absolute;
              bottom: 8px;
              left: 8px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #ffffff;
              line-height: 16px;
              text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
              text-align: left;
              font-style: normal;
              .leftTime {
                width: 66px;
                height: 22px;
                background: rgba(0, 0, 0, 0.5);
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .rightSize {
                width: 62px;
                height: 22px;
                background: rgba(0, 0, 0, 0.5);
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .stateBox {
              position: absolute;
              top: 8px;
              left: 8px;
              width: 66px;
              height: 22px;
              background: #0156ff;
              border-radius: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: rgba(255, 255, 255, 0.9);
              line-height: 16px;
              text-align: left;
              font-style: normal;
            }
          }
          .videoTitle {
            margin-top: 16px;
            width: 210px;
            height: 20px;
            font-family: PingFang-SC, PingFang-SC;
            font-weight: bold;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.9);
            line-height: 20px;
            text-align: left;
            font-style: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .videoBtnBox {
            margin-top: 13px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .creatTime {
              // width: 76px;
              height: 20px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.6);
              line-height: 20px;
              text-align: left;
              font-style: normal;
            }
            .detailBtn {
              width: 56px;
              height: 20px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #0156ff;
              line-height: 20px;
              text-align: left;
              font-style: normal;
              cursor: pointer;
            }
          }
        }
        .videoItem:hover {
          box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
          .detailBtn {
            width: 79px;
            height: 30px;
            background: #0156ff;
            border-radius: 6px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
