<!-- 从素材库上传、本地上传 -->
<template>
  <div class="slicingUpload">
    <div class="material">
      <el-upload
        ref="locaUpload"
        action=""
        :before-upload="handleBeforeUpload"
        :limit="1"
      >
        <i class="iconfont icon-shangchuan"></i>
        {{ $t('file.localUpload') }}
      </el-upload>
    </div>
    <div
      class="material marginLeft"
      @click="selectMaterial"
    >
      <i class="iconfont icon-sucaikubeifen"></i>{{ $t('videoTranslationPage.selectFromLibrary') }}
    </div>

    <el-drawer
      :title="$t('issueItem.product')"
      size="65%"
      :visible.sync="drawerWorks"
      :with-header="false"
    >
      <generated-video
        ref="generated"
        @selectionConfirmed="selectionConfirmed"
        @cancel="drawerWorks = false"
      ></generated-video>
    </el-drawer>
    <material-drawer
      ref="materialDrawer"
      :visible.sync="drawerMaterial"
      @selectionConfirmed="selectionMaterial"
    />
  </div>
</template>
<script>
import MaterialDrawer from "@/components/MaterialDrawer/index.vue";
import GeneratedVideo from "../../aivideo/generatedVideo.vue";
import { localTuClipAdd } from "../../../api/ai/material";
import { getOssToken } from "../../../api/ai/ali";
import OSS from "ali-oss";
export default {
  components: { MaterialDrawer, GeneratedVideo },
  props: {
    type: String,
    title: {
      type: String,
      default: "",
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 500,
    },
    // 文件类型
    fileType: {
      type: Array,
      default: () => ["png", "jpg", "jpeg", "webp", "mp4", "avi", "mkv", "mov"],
    },
    initNum: {
      type: Number,
      default: 5,
    },
  },
  watch: {},
  data() {
    return {
      credentials: null,
      loading: false,
      drawerWorks: false, //成品库
      drawerMaterial: false, //素材库
    };
  },
  computed: {
    computedTitle() {
      return this.title || this.$t("file.selectMaterial");
    },
  },
  created() {},
  methods: {
    cancelUpload() {
      if (this.loading) {
        this.$refs.locaUpload.abort(); // 调用 el-upload 的 abort 方法取消上传
        console.log("上传已取消");
        this.loading = false; // 更新上传状态
        this.$emit("uploading", false);
      }
    },
    // 从素材库选择
    selectMaterial() {
      this.drawerMaterial = true;
      this.$nextTick().then((rs) => {
        this.$refs.materialDrawer.$refs.materialLibrary.init(this.initNum);
      });
    },
    // 素材确认
    selectionMaterial(items) {
      this.$emit("selectMaterial", items);
      this.drawerMaterial = false;
    },
    // 从作品库选择
    selectWorks() {
      this.drawerWorks = true;
      this.$nextTick().then((rs) => {
        this.$refs.generated.init(2);
      });
    },
    // 作品确认
    selectionConfirmed(items) {
      this.$emit("selectMaterial", items);
      // 这里可以执行进一步的处理，例如保存到数据中等
      this.drawerWorks = false;
    },

    async uploadFile(file) {
      console.log(file);
      try {
        this.loading = true;
        this.$emit("uploading", true);
        const client = await this.getOssClient();
        const result = await client.put(
          `file/${this.getCurrentDate()}/${this.generateUniqueCode(
            30
          )}${file.name.replace(/^[^.]+/, "")}`,
          file
        );
        // console.log(result, '上传至oss的文件');
        // debugger
        const name = file.name.replace(/\.[^/.]+$/, "");
        const fileType = file.type.includes("video")
          ? "video"
          : file.type.includes("image")
          ? "image"
          : "";
        if (this.type === "materialType") {
          localTuClipAdd({
            ...file,
            fileType: fileType,
            url: result.url,
            fileSize: file.size,
            name: name,
          })
            .then((response) => {
              if (response.data) {
                if (!this.loading) return;
                this.$emit("showLocaMedia", [
                  {
                    url: response.data.url,
                    firstFrameImgUrl: response.data.firstFrameImgUrl,
                    videoLength: response.data.videoLength,
                    name: name,
                    fileType: fileType,
                  },
                ]);
                // this.$emit('showLocaMedia', [{url: result.url, firstFrameImgUrl: result.url, out: response.data.videoLength}])
                this.loading = false;
                this.$emit("uploading", false);
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$emit("uploading", false);
            });
        } else {
          if (!this.loading) return;
          this.$emit("showLocaMedia", [
            {
              url: result.url,
              firstFrameImgUrl: result.url,
              name: name,
              defaultName: file.name,
              fileType: fileType,
            },
          ]);
          this.loading = false;
          this.$emit("uploading", false);
          // localTuClipAdd({
          //   ...file,
          //   url: result.url,
          //   fileSize: file.size,
          //   name: file.name.replace(/\.[^/.]+$/, '')
          // })
          // .then(response => {
          //   if (response.data) {
          //     this.$emit('showLocaMedia', [{url: response.data.url, firstFrameImgUrl: response.data.firstFrameImgUrl, out: response.data.videoLength}])
          //     // this.$emit('showLocaMedia', [{url: result.url, firstFrameImgUrl: result.url, out: response.data.videoLength}])
          //     this.loading = false
          //   }
          // })
          // .catch(error => {
          //   this.loading = false
          // });
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
        this.$emit("uploading", false);
      }
    },
    async getOssClient() {
      // 临时凭证过期时重新获取，减少对STS服务的调用。
      if (this.isCredentialsExpired(this.credentials)) {
        this.credentials = await getOssToken();
      }
      return new OSS({
        bucket: process.env.VUE_APP_OSS_BUCKET, // OSS Bucket名称
        region: "oss-cn-beijing", // OSS所在地域
        accessKeyId: this.credentials.accessKeyId,
        accessKeySecret: this.credentials.accessKeySecret,
        stsToken: this.credentials.securityToken,
        timeout: 500000,
        secure: true,
      });
    },
    isCredentialsExpired(credentials) {
      if (!credentials) {
        return true;
      }
      const expireDate = new Date(credentials.Expiration);
      const now = new Date();
      return expireDate.getTime() - now.getTime() <= 60000; // 有效期不足一分钟，视为过期
    },
    handleBeforeUpload(file) {
      // 校检文件类型
      if (this.fileType) {
        const fileName = file.name.split(".");
        const fileExt = fileName[fileName.length - 1];
        const isTypeOk = this.fileType.indexOf(fileExt) >= 0;
        if (!isTypeOk) {
          this.$modal.msgError(
            `${this.$t("file.invalidFormat1")}${this.fileType.join(
              "/"
            )}${this.$t("file.invalidFormat2")}`
          );
          return false;
        }
      }
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$modal.msgError(
            `${this.$t("file.sizeExceed")} ${this.fileSize} MB!`
          );
          return false;
        }
      }
      this.uploadFile(file);
      // this.$modal.loading("正在上传文件，请稍候...");
      this.number++;
      return true;
    },
    generateRandomCode(length = 30) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
      return result;
    },
    generateUniqueCode(length) {
      const generatedCodes = new Set();
      let code;
      do {
        code = this.generateRandomCode(length);
      } while (generatedCodes.has(code));
      generatedCodes.add(code);
      return code;
    },
    getCurrentDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始，所以加1
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}/${month}/${day}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.slicingUpload {
  display: flex;
  align-items: center;
  margin-top: 22px;
  .material {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
    text-align: left;
    font-style: normal;
    cursor: pointer;
  }
  .marginLeft {
    margin-left: 32px;
  }
  .iconfont {
    font-size: 16px;
  }
  .icon-sucaikubeifen {
    margin-right: 6px;
  }
  .el-upload-list {
    margin: 0;
    padding: 0;
    display: none;
    list-style: none;
  }
}
</style>
