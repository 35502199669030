var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "videoGather" },
    [
      _c(
        "div",
        { staticClass: "faceContent" },
        [
          _c(
            "el-popover",
            { attrs: { placement: "top", width: "400", trigger: "hover" } },
            [
              [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("videoCarry.pleaseVideoLinkDy")),
                  },
                }),
              ],
              _c("el-input", {
                staticClass: "textareaStyle",
                attrs: {
                  slot: "reference",
                  type: "textarea",
                  rows: 2,
                  placeholder: _vm.$t("videoCarry.pleaseVideoLink"),
                  resize: "none",
                },
                slot: "reference",
                model: {
                  value: _vm.textareaUrl,
                  callback: function ($$v) {
                    _vm.textareaUrl = $$v
                  },
                  expression: "textareaUrl",
                },
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "btnBox flex-center" },
            [
              _c(
                "el-button",
                {
                  staticClass: "button-white",
                  on: { click: _vm.clearTextareaUrl },
                },
                [_vm._v(_vm._s(_vm.$t("videoCarry.clearContent")))]
              ),
              _c(
                "el-button",
                {
                  staticClass: "normal-button",
                  on: { click: _vm.pasteTextareaUrl },
                },
                [_vm._v(_vm._s(_vm.$t("videoCarry.clickPaste")))]
              ),
              _c(
                "el-button",
                {
                  staticClass: "gradient-button",
                  attrs: { loading: _vm.startAnalysisLoading },
                  on: { click: _vm.startAnalysis },
                },
                [_vm._v(_vm._s(_vm.$t("videoCarry.startParsing")))]
              ),
              _c(
                "div",
                { staticClass: "position-box flex-row-center" },
                [
                  _c("span", { staticClass: "f-shrink" }, [
                    _vm._v(
                      _vm._s(_vm.$t("videoTranslationPage.saveto")) + "："
                    ),
                  ]),
                  _c("treeselect", {
                    attrs: {
                      options: _vm.positionList,
                      normalizer: _vm.normalizer,
                      "load-options": _vm.loadOptions,
                      clearable: false,
                      placeholder: _vm.$t("file.tipSelectFolder"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "value-label",
                        fn: function (ref) {
                          var node = ref.node
                          return [_vm._v(" " + _vm._s(node.raw.name) + " ")]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.treeselectId,
                      callback: function ($$v) {
                        _vm.treeselectId = $$v
                      },
                      expression: "treeselectId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("historyDrawer", {
            ref: "historyDrawer",
            attrs: { type: "gather", drawerHistory: _vm.drawerHistory },
            on: { closeHistory: _vm.closeHistory },
          }),
        ],
        1
      ),
      _vm.dialogPlay
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.dialogPlay, width: "50%" },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogPlay = $event
                },
              },
            },
            [
              _c("video", {
                ref: "videoPlayer",
                staticClass: "video-player",
                attrs: { controls: "", src: _vm.playUrl },
              }),
            ]
          )
        : _vm._e(),
      _vm.publishVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "publicDialog",
              attrs: {
                width: "50%",
                visible: _vm.publishVisible,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                "before-close": _vm.handleBeforeClose,
                "show-close": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.publishVisible = $event
                },
              },
            },
            [
              _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                _c("div", { staticClass: "headerFlex" }, [
                  _c("div", { staticClass: "headerTitle" }, [
                    _vm._v(_vm._s(_vm.$t("videoCarry.editingCopy"))),
                  ]),
                  _c("i", {
                    staticClass: "el-icon-close",
                    on: { click: _vm.handleBeforeClose },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "dialogContent" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "ruleForm",
                        staticClass: "demo-ruleForm",
                        attrs: { model: _vm.ruleForm, rules: _vm.rules },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "postContent" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "videoCarry.PleaseEnterText"
                                ),
                                type: "textarea",
                                rows: 4,
                                resize: "none",
                              },
                              model: {
                                value: _vm.ruleForm.postContent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "postContent", $$v)
                                },
                                expression: "ruleForm.postContent",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "account" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  multiple: "",
                                  placeholder: _vm.$t(
                                    "videoCarry.selectAccount"
                                  ),
                                },
                                model: {
                                  value: _vm.ruleForm.account,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "account", $$v)
                                  },
                                  expression: "ruleForm.account",
                                },
                              },
                              _vm._l(_vm.accountOptions, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.username,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "cancelBtn",
                            on: { click: _vm.handleBeforeClose },
                          },
                          [_vm._v(_vm._s(_vm.$t("cancel")))]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "submitBtn",
                            attrs: { loading: _vm.buttonLoading },
                            on: {
                              click: function ($event) {
                                return _vm.submitForm("ruleForm")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("videoManager.publish")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }