var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-container" }, [
    _c("video", {
      ref: "video",
      attrs: { src: _vm.videoUrl },
      on: { loadedmetadata: _vm.onVideoLoad },
    }),
    _c("canvas", { ref: "canvas", staticClass: "overlay-canvas" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }