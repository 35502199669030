<template>
  <div class="drawer-history">
    <!-- <el-drawer
      :size="700"
      direction="rtl"
      :visible="drawerHistory"
      :modal="true"
      :before-close="handleClose"
      :withHeader="false"
    > -->
    <!-- <div class="header-history">
        {{$t('videoTranslationPage.history')}}
        <i class="el-icon-close" @click="handleClose"></i>
      </div> -->
    <div
      class="conyent-history"
      v-loading="loading && !isRefresh"
    >
      <div class="historyList">
        <div
          class="historyItem"
          v-for="item in taskList"
          :key="item.id"
          @click="viewItem(item)"
        >
          <img
            mode="aspectFill"
            class="imageCover"
            :src="item.finishedCover || item.sourceCover"
            alt=""
          >
          <div
            class="historyItem-state"
            :class="item.jobStatus"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.errorMsg"
              placement="top"
            >
              <i
                class="iconfont icon-zhushi"
                v-if="item.jobStatus === 'Failed'"
              ></i>
            </el-tooltip>
            {{ getStatus(item.jobStatus) }}
          </div>
          <div class="createTime">
            {{ item.createTime.split(' ')[0] }}
          </div>
        </div>
      </div>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </div>
    <!-- </el-drawer> -->
    <el-dialog
      v-if="dialogPlay"
      :visible.sync="dialogPlay"
      width="50%"
    >
      <video
        controls
        :src="playUrl"
        class="video-player"
        ref="videoPlayer"
      ></video>
    </el-dialog>
  </div>
</template>

<script>
import { getTaskList } from "@/api/ai/aiFacewarp";
export default {
  props: {
    drawerHistory: {
      type: Boolean,
      default: false,
    },
    type: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      total: 10,
      loading: false,
      jobType: "mergeFace",
      queryParams: {},
      taskList: [],
      dialogPlay: false,
      playUrl: "",
      timer: null, //定时器——执行中的状态更新
      isRefresh: false, //是否自动刷新
    };
  },
  created() {
    this.getList();
  },
  beforeDestroy() {
    // 页面销毁前清除定时器
    this.clearTimer();
  },
  methods: {
    getStatus(status) {
      if (status === "Executing") {
        return this.$t("operationState.inProgress");
      } else if (status === "Finished") {
        return this.$t("operationState.completed");
      } else if (status === "Failed") {
        return this.$t("issueManager.failed");
      } else if (status === "Saving") {
        return this.$t("videoCarry.savingStatus");
      } else if (status === "Publishing") {
        return this.$t("operationState.publishing");
      } else {
        return status;
      }
    },
    viewItem(item) {
      if (item.finishedUrl && item.jobStatus === "Finished") {
        this.playUrl = item.finishedUrl;
        this.dialogPlay = true;
        this.$nextTick(() => {
          this.videoElement = this.$refs.videoPlayer;
          this.videoElement.play();
        });
      } else {
        this.$message({
          message: this.$t("videoTranslationPage.noFinishedVideoLink"),
          type: "warning",
        });
      }
    },
    async getList() {
      try {
        this.loading = true;
        const data = await getTaskList({ jobType: this.type });
        const { rows, total } = data;
        this.taskList = rows;
        this.total = total;
        this.loading = false;
        // 检查是否存在 生成中 的项
        if (
          this.taskList.some(
            (item) =>
              item.jobStatus === "Executing" || item.jobStatus === "Saving"
          )
        ) {
          // 如果定时器不存在，启动定时器
          if (!this.timer) {
            this.timer = setInterval(() => {
              this.isRefresh = true;
              this.getList();
            }, 30000); // 每30秒调用一次
          }
        } else {
          this.clearTimer();
        }
        console.log(data);
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    clearTimer() {
      this.isRefresh = false;
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    handleClose() {
      this.$emit("closeHistory");
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer-history {
  // width: 700px;
  position: relative;
}
.el-drawer {
  border-radius: 12px 0 0 12px;
}
.header-history {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
  padding: 0 24px;
  width: 700px;
  height: 40px;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  .el-icon-close {
    color: #387dee;
  }
}
.conyent-history {
  margin-top: 40px;
  padding: 20px;
  overflow-x: hidden;
  .historyList {
    display: flex;
    flex-wrap: wrap;
    .historyItem {
      width: 220px;
      height: 346px;
      border-radius: 16px;
      // opacity: 0.8;
      margin-right: 15px;
      margin-bottom: 24px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      .historyItem-state {
        position: absolute;
        top: 10px;
        right: 10px;
        // width: 52px;
        padding: 0 8px;
        height: 18px;
        // background: #016FEE;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        line-height: 18px;
        text-align: left;
        font-style: normal;
        .icon-zhushi {
          font-size: 12px;
          margin-right: 3px;
        }
      }
      .Failed {
        background: #ff0000;
      }
      .Executing {
        background: #eeaa01;
      }
      .Finished {
        background: #016fee;
      }
      .Saving {
        background: #00ac6c;
      }
      .historyItem-title {
        position: absolute;
        top: 10px;
        left: 10px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #fff;
        line-height: 22px;
        text-align: left;
        font-style: normal;
      }

      .createTime {
        position: absolute;
        bottom: 12px;
        right: 10px;
        font-family: PingFang-SC, PingFang-SC;
        font-weight: 500;
        font-size: 12px;
        color: #fff;
        line-height: 18px;
        // text-shadow: 0px 2px 4px rgba(0,0,0,0.2);
      }
      .imageCover {
        background: black;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    // .historyItem:nth-child(5n) {
    //   margin-right: 0;
    // }
  }
}
</style>
