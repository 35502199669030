<template>
  <div class="aiSlicingDetail" v-loading="loading">
    <div class="actionBtn">
      <div class="leftBtn btn" @click="goToMaterial">
        <i class="iconfont icon-a-xialajiantoubeifen3"></i>
        {{ $t('aiSlicing.goLibraryToView') }}
      </div>
      <div class="btn" @click="goBack">
        <i class="iconfont icon-fanhui"></i>
        {{ $t('aiSlicing.returningSlice') }}
      </div>
    </div>
    <div class="detailContent">
      <div class="detailItem" v-for="(item, index) in detailList" :key="index">
        <div class="headerTip">
          <i class="iconfont icon-xing"></i>
          {{ $t('aiSlicing.sliceInformation') }}
        </div>
        <div class="detailItemTitle">
          <div class="blue-text">#{{ index+1 }}</div>
          <div class="detail-title" v-if="!item.isEditTitle">{{ item.name }}</div>
          <el-input
            v-else
            :ref="`titleInputRef-${index}`"
            v-model="item.editTitleVal"
            @blur="titleBlur(item, index)"
          />
          <i class="iconfont icon-bianji2" v-if="!item.isEditTitle" @click="editTitle(item, index)"></i>
        </div>
        <div class="detail-item-content">
          <div class="left-num">
            <span class="blue-num">{{ index+1 }}</span>
            <span class="num">/{{ detailList.length }}</span>
          </div>
          <div class="item-content">
            <div class="video-box">
              <video
                controls
                :src="item.url"
                class="video-player"
                ref="videoPlayer"
              ></video>
            </div>
            <div class="item-describe">
              <div class="describe-header">
                <div class="describe-header-title">
                  <div class="left-box"></div>
                  {{ $t('aiSlicing.fragmentDescription') }}
                </div>
                <i class="iconfont icon-bianji2" v-if="!item.isEditDescribe" @click="editContent(item, index)"></i>
              </div>
              <div class="line"></div>
              <div class="describe-content" v-if="!item.isEditDescribe">
                {{ item.description }}
              </div>
              <el-input
                v-else
                type="textarea"
                :autosize="{ maxRows: 12 }"
                resize="none"
                :ref="`contentInputRef-${index}`"
                v-model="item.editDescribeVal"
                maxlength="250"
                @blur="contentBlur(item, index)">
              </el-input>
            </div>
          </div>
          <div class="right-tag">
            <div class="tag-box" v-for="(tag, tagIndex) in item.tags" :key="tagIndex+1" :style="{background: tagStyle[tagIndex].bg, color: tagStyle[tagIndex].color}">
              #{{ tag }}
              <i v-if="item.isEditTags" class="iconfont icon-dacha" @click="deleteTag(item, tagIndex)"></i>
            </div>
            <div v-for="(select, selectIndex) in item.selects" :key="selectIndex" class="tag-select-container">
              <el-select v-model="select.selectedTag" :placeholder="$t('aiSlicing.pleaseSelect')" v-if="item.isEditTags">
                <el-option
                  v-for="dict in labelList"
                  :key="dict.id"
                  :label="dict.label"
                  :value="dict.label"
                ></el-option>
              </el-select>
              <i v-if="item.isEditTags" @click="deleteSelect(item, selectIndex)" class="iconfont icon-dacha"></i>
            </div>
            <div class="action-btn"  v-if="item.isEditTags">
              <div class="add-tag-btn" @click="addSelect(item, index)">
                <i class="iconfont icon-tianjia"></i>
                {{ $t('aiSlicing.addTags') }}
              </div>
              <div class="save-tag-btn" @click="saveTags(item, index)">
                <i class="iconfont icon-sousuotiaojianbaocun"></i>
                {{ $t('aiSlicing.saveContent') }}
              </div>
            </div>
            <div class="edit-tag-btn" v-if="!item.isEditTags" @click="editTags(item, index)">
              <i class="iconfont icon-bianji2"></i>
              {{ $t('aiSlicing.editTags') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {listLabel} from '@/api/system/label';
import { listMaterial, editMaterial } from '@/api/ai/material';
  export default {
    props: {
      detailIds: {
        type: Array,
        default: () => []
      },
      folderId: {
        type: Number,
      }
    },
    data() {
      return {
        titleVal: '',
        labelList: [],
        detailList: [],
        loading: false,
        tagStyle: [
          {
            bg: '#ECF2FE',
            color: '#016FEE'
          },
          {
            bg: '#E8F8F2',
            color: '#00AC6C'
          },
          {
            bg: '#FEF3E6',
            color: '#EE7A01'
          },
          {
            bg: '#D5FFFF',
            color: '#00919F'
          },
          {
            bg: '#FEE6FF',
            color: '#CA20D4'
          },
          {
            bg: '#FFF2D9',
            color: '#F8BD00'
          },
          {
            bg: '#ECF2FE',
            color: '#016FEE'
          },
          {
            bg: '#E8F8F2',
            color: '#00AC6C'
          },
          {
            bg: '#FEF3E6',
            color: '#EE7A01'
          },
          {
            bg: '#D5FFFF',
            color: '#00919F'
          },
          {
            bg: '#FEE6FF',
            color: '#CA20D4'
          },
          {
            bg: '#FFF2D9',
            color: '#F8BD00'
          }
        ]
      }
    },
    async created() {
      await this.getDetailList()
      await this.getLabels()
      console.log(this.detailIds, 'props');
    },
    methods: {
      filterLabel(label) {
        return label ? label?.split(',') : []
      },
      async getDetailList(type) {
        try {
          this.loading = true
          const { rows } = await listMaterial({
            materialTypeId: this.folderId,
            ids: this.detailIds
          })
          const newList = rows.map(item => {
            return {
              ...item,
              tags: this.filterLabel(item.label),
              isEditTitle: false,
              editTitleVal: '',
              isEditDescribe: false,
              editDescribeVal: '',
              isEditTags: false,
              selects: this.filterLabel(item.label) >= 8 ? [] : [
                { selectedTag: "" }
              ]
            }
          })
          const rowsMap = new Map(newList.map(item => [item.id, item]));
          this.detailList = this.detailIds.map(id => rowsMap.get(id)).filter(Boolean);
          this.loading = false
        } catch (e) {
          console.log(e);
          this.loading = true
        }
      },
      addSelect(item) {
        if((item.selects.length + item.tags.length) < 8) {
          item.selects.push({ selectedTag: "" });
        } else {
          this.$message.warning(this.$t('aiSlicing.addTagsLimit'))
        }
      },
      deleteSelect(item, index) {
        item.selects.splice(index, 1);
      },
      deleteTag(item, index) {
        item.tags.splice(index, 1);
      },
      async saveTags(item) {
        const selectedTags = item.selects.map(select => select.selectedTag).filter(tag => tag);  // 提取所有非空标签
        await this.editMaterialFun({
          ...item,
          label: item.tags.concat(selectedTags).join(',') || ''
        })
        await this.getDetailList()
        item.isEditTags = false;
        this.$message.success(this.$t('aiSlicing.tagTip'));
      },
      editTags(item, index) {
        if((item.selects.length + item.tags.length) < 8) {
          this.$set(item, "isEditTags", true)
        } else {
          this.$message.warning(this.$t('aiSlicing.addTagsLimit'))
        }
      },
      getLabels() {
        listLabel().then(response => {
          this.labelList = response.rows;
        });
      },
      // 编辑描述
      editContent(item, index) {
        this.$set(item, "isEditDescribe", true)
        this.$set(item, "editDescribeVal", item.description)
        this.$nextTick(() => {
          this.$refs[`contentInputRef-${index}`][0]?.focus()
        });
      },
      async contentBlur(item, index) {
        if (!item.editDescribeVal.trim()) {
          this.$message.warning(this.$t('aiSlicing.contentTip'))
          this.$nextTick(() => {
            this.$refs[`contentInputRef-${index}`][0]?.focus()
          });
          return;
        }
        await this.editMaterialFun({...item, description: item.editDescribeVal.trim()})
        await this.getDetailList()
      },
      // 编辑标题
      editTitle(item, index) {
        this.$set(item, "isEditTitle", true)
        this.$set(item, "editTitleVal", item.name)
        this.$nextTick(() => {
          this.$refs[`titleInputRef-${index}`][0]?.focus()
        });
      },
      async titleBlur(item, index) {
        if (!item.editTitleVal.trim()) {
          this.$message.warning(this.$t('aiSlicing.titleTip'))
          this.$nextTick(() => {
            this.$refs[`titleInputRef-${index}`][0]?.focus()
          });
          return;
        }
        await this.editMaterialFun({...item, name: item.editTitleVal.trim()})
        await this.getDetailList('title')
      },
      async editMaterialFun(data) {
        try {
          this.loading = true
          await editMaterial(data)
          this.loading = false
        } catch (e) {
          console.log(e);
          this.loading = false
        }
      },
      goToMaterial() {
        this.$router.push({
          path: '/material',
          query: { folderId: this.folderId }
        })
      },
      goBack() {
        this.$emit('goBackSlicing')
      }
    },
  }
</script>

<style lang="scss" scoped>
.aiSlicingDetail {
  height: 100%;
  position: relative;
  .actionBtn {
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;
    align-items: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,0.6);
    line-height: 20px;
    text-align: left;
    font-style: normal;
    .leftBtn {
      margin-right: 28px;
    }
    .btn {
      cursor: pointer;
    }
  }
  .detailContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    padding-top: 34px;
    padding-left: 30px;
    .detailItem {
      width: 913px;
      min-height: 400px;
      margin-bottom: 60px;
      .headerTip {
        margin-left: 80px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0,0,0,0.6);
        line-height: 20px;
        text-align: left;
        font-style: normal;
        .icon-xing {
          background-image: linear-gradient( 180deg, #5896FF 0%, #434EFF 100%);
          -webkit-background-clip: text;
          color:transparent;
        }
      }
      .detailItemTitle {
        display: flex;
        align-items: center;
        margin-top: 16px;
        margin: 8px 0 20px 50px;
        .blue-text {
          height: 28px;
          font-family: PingFang-SC, PingFang-SC;
          font-weight: 500;
          font-size: 20px;
          color: #0156FF;
          line-height: 28px;
          text-align: left;
          font-style: normal;
        }
        .el-input {
          width: 450px;
          .el-input__inner {
            width: 450px;
            height: 32px;
            background: #F4F4F5;
            border-radius: 8px;
            border: none;
          }
        }
        .detail-title {
          width: 60%;
          height: 28px;
          font-family: PingFang-SC, PingFang-SC;
          font-weight: 500;
          font-size: 20px;
          color: rgba(0,0,0,0.9);
          line-height: 28px;
          text-align: left;
          font-style: normal;
          margin-left: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .icon-bianji2 {
          font-size: 14px;
          margin-left: 8px;
        }
      }
      .detail-item-content {
        display: flex;
        height: 100%;
        .left-num {
          margin-right: 15px;
          .blue-num {
            height: 33px;
            font-family: PingFang-SC, PingFang-SC;
            font-weight: 500;
            font-size: 24px;
            color: #0156FF;
            line-height: 33px;
            text-align: left;
            font-style: normal;
          }
          .num {
            height: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #71717A;
            line-height: 20px;
            text-align: left;
            font-style: normal;
          }
        }
        .item-content {
          min-width: 600px;
          width: 600px;
          height: 340px;
          background: #FFFFFF;
          border-radius: 12px;
          border: 1px solid rgba(179,179,179,0.38);
          display: flex;
          .video-box {
            width: 200px;
            height: 340px;
            background: #000;
            border-radius: 12px;
            video {
              width: 200px;
              height: 340px;
              border-radius: 12px;
            }
          }
          .item-describe {
            flex: 1;
            padding: 12px 20px 12px 16px;
            .describe-header {
              display: flex;
              justify-content: space-between;
              .describe-header-title {
                display: flex;
                align-items: center;
                font-family: PingFang-SC, PingFang-SC;
                font-weight: bold;
                font-size: 14px;
                color: rgba(0,0,0,0.9);
                line-height: 20px;
                text-align: left;
                font-style: normal;
                .left-box {
                  width: 4px;
                  height: 18px;
                  background: #0156FF;
                  border-radius: 2px;
                  margin-right: 8px;
                }
              }
            }
            .line {
              width: 100%;
              height: 1px;
              background: rgba(0,0,0,0.1);
              margin-top: 10px;
            }
            .describe-content {
              max-width: 365px;
              padding-top: 10px;
              height: calc(100% - 40px);
              overflow-y: auto;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: rgba(0,0,0,0.9);
              line-height: 24px;
              text-align: left;
              font-style: normal;
            }
            .el-textarea__inner {
              margin-top: 10px;
              background: #F4F4F5;
              border-radius: 8px;
              border: none;
            }
          }
        }
        .right-tag {
          padding-left: 20px;
          .tag-box {
            padding: 6px 12px;
            border-radius: 8px;
            margin-bottom: 10px;
            width: fit-content;
            .icon-dacha {
              color: #8E9198 !important;
              cursor: pointer;
              margin-left: 3px;
            }
          }
          .tag-select-container {
            .icon-dacha {
              color: #8E9198 !important;
              cursor: pointer;
              margin-left: 3px;
            }
          }
          .el-select {
            .el-input--medium .el-input__inner {
              width: 217px;
              height: 34px;
              background: #FFFFFF;
              border-radius: 8px;
              border: 1px solid #E5E7EB;
            }
            margin-bottom: 10px;
          }
          .action-btn {
            display: flex;
            align-items: center;
            z-index: 999;
            .add-tag-btn {
              padding: 6px 15px;
              background: #FFFFFF;
              border-radius: 8px;
              border: 1px solid #0156FF;
              width: fit-content;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #0156FF;
              line-height: 22px;
              text-align: center;
              font-style: normal;
              cursor: pointer;
            }
            .save-tag-btn {
              padding: 6px 15px;
              background: #0156FF;
              border-radius: 8px;
              border: 1px solid #0156FF;
              width: fit-content;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #fff;
              line-height: 22px;
              text-align: center;
              font-style: normal;
              cursor: pointer;
              margin-left: 8px;
            }
          }
          .edit-tag-btn {
            padding: 6px 15px;
            background: #FFFFFF;
            border-radius: 8px;
            border: 1px solid #0156FF;
            width: fit-content;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #0156FF;
            line-height: 22px;
            text-align: center;
            font-style: normal;
            cursor: pointer;
          }
        }
      }
    }
  }
  .icon-bianji2 {
    cursor: pointer;
  }
}
</style>
