var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "aiSlicing" }, [
    _c(
      "div",
      { staticClass: "slicingContent" },
      [
        !_vm.isViewDetail
          ? _c("div", { staticClass: "slicingFrom" }, [
              _c("div", { staticClass: "slicingFromTitle" }, [
                _vm._v(_vm._s(_vm.$t("aiSlicing.contentTitle"))),
              ]),
              _c(
                "div",
                { staticClass: "slicingFromBox" },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "inputStyle",
                      attrs: {
                        placeholder: _vm.$t("videoCarry.pleaseVideoLink"),
                      },
                      on: { change: _vm.changeInput },
                      model: {
                        value: _vm.videoUrl,
                        callback: function ($$v) {
                          _vm.videoUrl = $$v
                        },
                        expression: "videoUrl",
                      },
                    },
                    [
                      _c("i", {
                        class: [
                          "iconfont",
                          _vm.iconType === "loca"
                            ? "icon-shangchuan"
                            : _vm.iconType === "material"
                            ? "icon-sucaikubeifen"
                            : "icon-lianjiebeidianjiliang",
                        ],
                        attrs: { slot: "prefix" },
                        slot: "prefix",
                      }),
                      _vm.videoUrl
                        ? _c(
                            "div",
                            {
                              staticClass: "delBtn",
                              attrs: { slot: "suffix" },
                              on: { click: _vm.deleteVideo },
                              slot: "suffix",
                            },
                            [_vm._v(_vm._s(_vm.$t("aiSlicing.delete")))]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c("slicingUpload", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.uploadBoxLoading,
                        expression: "!uploadBoxLoading",
                      },
                    ],
                    ref: "slicingUpload",
                    attrs: { fileType: _vm.fileType, initNum: 6 },
                    on: {
                      selectMaterial: _vm.selectMaterial,
                      showLocaMedia: _vm.showLocaMedia,
                      uploading: _vm.uploading,
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.uploadBoxLoading,
                          expression: "uploadBoxLoading",
                        },
                      ],
                      staticClass: "uploadLoading",
                    },
                    [
                      _c("div", { staticClass: "leftLoading" }, [
                        _c("img", {
                          staticClass: "loadingPic",
                          attrs: {
                            src: require("@/assets/images/loadingGif.gif"),
                            alt: "",
                          },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("aiSlicing.uploadingMaterial")) +
                            "... "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "cancelUpload",
                          on: { click: _vm.cancelUpload },
                        },
                        [_vm._v(_vm._s(_vm.$t("aiSlicing.cancel")))]
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      class: [
                        "gradient-button",
                        _vm.uploadBoxLoading && "loadingBtn",
                      ],
                      attrs: {
                        disabled: _vm.uploadBoxLoading,
                        type: "primary",
                      },
                      on: { click: _vm.startSlicing },
                    },
                    [_vm._v(_vm._s(_vm.$t("aiSlicing.startSlicing")))]
                  ),
                  !_vm.videoUrl
                    ? _c("div", { staticClass: "tip" }, [
                        _vm._v(_vm._s(_vm.$t("aiSlicing.uploadTip"))),
                      ])
                    : _c(
                        "div",
                        { staticClass: "saveMaterialBox" },
                        [
                          _c("div", { staticClass: "saveMaterialTitle" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("aiSlicing.saveTitle")) +
                                "： "
                            ),
                          ]),
                          _c("treeselect", {
                            attrs: {
                              options: _vm.treeFileList,
                              "show-count": false,
                              normalizer: _vm.normalizer,
                              clearable: false,
                              "load-options": _vm.loadOptions,
                              placeholder: _vm.$t("aiSlicing.saveLocation"),
                              disabled: false,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "value-label",
                                  fn: function (ref) {
                                    var node = ref.node
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            node.raw.name
                                              ? node.raw.name
                                              : _vm.$t("aiSlicing.saveLocation")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3475094465
                            ),
                            model: {
                              value: _vm.materialTypeId,
                              callback: function ($$v) {
                                _vm.materialTypeId = $$v
                              },
                              expression: "materialTypeId",
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
              _vm.videoItem.url
                ? _c("div", { staticClass: "viewUploadVideo" }, [
                    _c("video", {
                      attrs: { controls: "", src: _vm.videoItem.url },
                    }),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "historyBox",
                },
                _vm._l(_vm.taskList, function (item) {
                  return _c("div", { key: item.id, staticClass: "videoItem" }, [
                    _c("div", { staticClass: "material-container" }, [
                      _c("video", {
                        staticClass: "videoBox",
                        attrs: { src: item.sourceUrl },
                      }),
                      _c("div", { staticClass: "userInfo" }, [
                        item.duration
                          ? _c("div", { staticClass: "leftTime" }, [
                              _vm._v(" " + _vm._s(item.duration) + " "),
                            ])
                          : _vm._e(),
                        item.fileSize
                          ? _c("div", { staticClass: "rightSize" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formatFileSize(item.fileSize)) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "stateBox" }, [
                        _vm._v(
                          " " + _vm._s(_vm.filterStatus(item.jobStatus)) + " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "videoTitle" }, [
                      _vm._v(" " + _vm._s(item.videoName) + " "),
                    ]),
                    _c("div", { staticClass: "videoBtnBox" }, [
                      _c("div", { staticClass: "creatTime" }, [
                        _vm._v(_vm._s(_vm.filterTime(item.createTime))),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "detailBtn",
                          on: {
                            click: function ($event) {
                              return _vm.viewDetail(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("aiSlicing.checkTheDetails")))]
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ])
          : _c("aiSlicingDetail", {
              attrs: { detailIds: _vm.detailIds, folderId: _vm.folderId },
              on: { goBackSlicing: _vm.goBackSlicing },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }