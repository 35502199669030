<!-- 功能整合——（现）视频搬运 -->
<template>
  <div class="videoCarry">
    <div
      class="faceContent"
      v-show="!isAnalysis"
    >
      <el-popover
        placement="top"
        width="400"
        trigger="hover"
      >
        <template>
          <span v-html="$t('videoCarry.pleaseVideoLinkDy')"></span>
        </template>
        <el-input
          slot="reference"
          class="textareaStyle"
          type="textarea"
          :rows="2"
          :placeholder="$t('videoCarry.pleaseVideoLink')"
          resize="none"
          v-model="textareaUrl"
        >
        </el-input>
      </el-popover>
      <div class="btnBox flex-center">
        <el-button
          class="button-white"
          @click="clearTextareaUrl"
        >{{ $t('videoCarry.clearContent') }}</el-button>
        <el-button
          class="normal-button"
          @click="pasteTextareaUrl"
        >{{ $t('videoCarry.clickPaste') }}</el-button>
        <el-button
          class="gradient-button"
          @click="startAnalysis"
          :loading="startAnalysisLoading"
        >{{ $t('videoCarry.startParsing') }}</el-button>
        <!-- <div class="position-box flex-row-center">
          <span class="f-shrink">{{ $t('videoTranslationPage.saveto') }}：</span>
          <treeselect
            v-model="treeselectId"
            :options="positionList"
            :normalizer="normalizer"
            :load-options="loadOptions"
            :clearable="false"
            :placeholder="$t('file.tipSelectFolder')"
          >
            <template #value-label="{ node }">
              {{ node.raw.name }}
            </template>
          </treeselect>
        </div> -->
      </div>
    </div>
    <div
      v-if="isAnalysis"
      class="flex-column-center"
    >
      <div class="videoContent flex-center">
        <div
          class="videoBox"
          v-if="videoUrl"
        >
          <video
            ref="video"
            :src="videoUrl"
          ></video>
        </div>
        <div
          v-if="videoUrl"
          class="deleteIcon"
          @click="removeMaterial"
        >
          <i class="iconfont icon-delete"></i>
        </div>
      </div>

      <div class="setContent">
        <el-form
          :model="videoForm"
          ref="basicInfoForm"
          width="100%"
          label-position="top"
        >
          <el-checkbox-group v-model="videoForm.jobProgressList">
            <el-checkbox label="videoTranslation">{{
                  $t('videoTranslationPage.headerTitle') }}</el-checkbox>
            <el-checkbox label="mergeFace">{{
                  $t('faceSwap.faceChanging') }}</el-checkbox>
            <el-checkbox label="eraseSubtitle">{{
                  $t('workbench.aiSubtitleRemoval') }}</el-checkbox>
          </el-checkbox-group>
          <div v-if="videoForm.jobProgressList.includes('videoTranslation')">
            <el-form-item
              :label="$t('videoTranslationPage.translationType')"
              style="margin-top: 34px;"
            >
              <el-checkbox
                v-model="videoTranslation.needSpeechTranslate"
                @change="changeType"
              > {{$t('videoTranslationPage.sound') }}</el-checkbox>
              <el-checkbox
                v-model="videoTranslation.needSubtitleTranslate"
                @change="changeType"
              >{{$t('videoTranslationPage.subtitle') }}</el-checkbox>
              <el-checkbox
                v-model="videoTranslation.needFaceTranslate"
                @change="changeType"
              >{{$t('videoTranslationPage.face') }}</el-checkbox>
            </el-form-item>
            <el-form-item :label="$t('videoTranslationPage.videoLanguage')">
              <el-select
                v-if="videoTranslation.needFaceTranslate"
                v-model="videoTranslation.sourceLanguage"
                :placeholder="$t('tip.select')"
                clearable
                filterable
                :default-first-option="true"
                class="w-100"
              >
                <el-option
                  v-for="dict in dict.type.source_face_language"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                >
                  <span>{{ dict.label }}</span>
                </el-option>
              </el-select>
              <el-select
                v-else-if="videoTranslation.needSubtitleTranslate"
                v-model="videoTranslation.sourceLanguage"
                :placeholder="$t('tip.select')"
                clearable
                filterable
                :default-first-option="true"
                class="w-100"
              >
                <el-option
                  v-for="dict in dict.type.source_text_language"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                >
                  <span>{{ dict.label }}</span>
                </el-option>
              </el-select>
              <el-select
                v-else
                v-model="videoTranslation.sourceLanguage"
                :placeholder="$t('tip.select')"
                clearable
                filterable
                :default-first-option="true"
                class="w-100"
              >
                <el-option
                  v-for="dict in dict.type.source_speech_language"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                >
                  <span>{{ dict.label }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('videoTranslationPage.translationLanguage')">
              <el-select
                v-if="videoTranslation.needFaceTranslate"
                v-model="videoTranslation.targetLanguage"
                :placeholder="$t('tip.select')"
                clearable
                filterable
                :default-first-option="true"
                class="w-100"
              >
                <el-option
                  v-for="dict in dict.type.target_face_language"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                >
                  <span>{{ dict.label }}</span>
                </el-option>
              </el-select>
              <el-select
                v-else-if="videoTranslation.needSpeechTranslate"
                v-model="videoTranslation.targetLanguage"
                :placeholder="$t('tip.select')"
                clearable
                filterable
                :default-first-option="true"
                class="w-100"
              >
                <el-option
                  v-for="dict in dict.type.target_speech_language"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                >
                  <span>{{ dict.label }}</span>
                </el-option>
              </el-select>
              <el-select
                v-else
                v-model="videoTranslation.targetLanguage"
                :placeholder="$t('tip.select')"
                clearable
                filterable
                :default-first-option="true"
                class="w-100"
              >
                <el-option
                  v-for="dict in dict.type.target_text_language"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                >
                  <span>{{ dict.label }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="videoTranslation.needSubtitleTranslate"
              :label="$t('videoTranslationPage.keepSubtitle')"
            >
              <el-radio
                v-model="videoTranslation.bilingualSubtitle"
                :label="true"
              >{{ $t('videoTranslationPage.keep')
                  }}</el-radio>
              <el-radio
                v-model="videoTranslation.bilingualSubtitle"
                :label="false"
              >{{ $t('videoTranslationPage.discard')
                  }}</el-radio>
            </el-form-item>
          </div>
          <div v-if="videoForm.jobProgressList.includes('mergeFace')">
            <div class="vt-tip">
              <i class="iconfont icon-zhushi"></i>
              {{ $t('faceSwap.tip') }},&nbsp;{{ $t('file.invalidResolution') }}
            </div>
            <div class="addFaceBox">
              <div class="add-title">{{ $t('faceSwap.uploadFaceShape') }}:</div>
              <div class="uploadFace">
                <aiUploadPic
                  v-if="!facePicUrl"
                  @picSelectMaterial="chooseMaterial(4)"
                  @picSelectSystem="chooseSystemPic"
                  @showLocaPic="showLocaPic4"
                />
                <div
                  class="facePicBox"
                  v-if="facePicUrl"
                >
                  <img
                    :src="facePicUrl"
                    alt=""
                  />
                  <div
                    class="deleteIcon"
                    @click="removeFacePic"
                  >
                    <i class="iconfont icon-delete"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <el-button
        class="gradient-button"
        @click="goToPublish"
      >一键发布</el-button>
    </div>
    <historyDrawer
      ref="historyDrawer"
      type="transport"
      :drawerHistory="drawerHistory"
      @closeHistory="closeHistory"
    />
    <material-drawer
      ref="materialDrawer"
      :visible.sync="drawer"
      @selectionConfirmed="selectionConfirmed"
    />
    <el-dialog
      v-if="dialogPlay"
      :visible.sync="dialogPlay"
      width="50%"
    >
      <video
        controls
        :src="playUrl"
        class="video-player"
        ref="videoPlayer"
      ></video>
    </el-dialog>
    <el-dialog
      class="publicDialog"
      width="50%"
      v-if="publishVisible"
      :visible.sync="publishVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleBeforeClose"
      :show-close="false"
    >
      <div slot="title">
        <div class="headerFlex">
          <div class="headerTitle">{{ $t('videoCarry.editingCopy') }}</div>
          <i
            class="el-icon-close"
            @click="handleBeforeClose"
          ></i>
        </div>
        <div class="dialogContent">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <el-form-item prop="postContent">
              <el-input
                :placeholder="$t('videoCarry.PleaseEnterText')"
                type="textarea"
                :rows="4"
                resize="none"
                v-model="ruleForm.postContent"
              ></el-input>
            </el-form-item>
            <el-form-item prop="account">
              <el-select
                style="width: 100%;"
                v-model="ruleForm.account"
                multiple
                :placeholder="$t('videoCarry.selectAccount')"
              >
                <el-option
                  v-for="item in accountOptions"
                  :key="item.id"
                  :label="item.username"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div
            slot="footer"
            class="dialog-footer"
          >
            <el-button
              class="cancelBtn"
              @click="handleBeforeClose"
            >{{ $t('cancel') }}</el-button>
            <el-button
              class="submitBtn"
              :loading="buttonLoading"
              @click="submitForm('ruleForm')"
            >{{ $t('videoManager.publish') }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { releasePublish } from "../../api/ai/publish";
import { listAllAccount } from "../../api/account/tiktok";
import { getTaskList } from "../../api/ai/aiFacewarp";
import historyDrawer from "./components/historyDrawer.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { listProductType } from "../../api/ai/productType";
import { listMaterialType } from "../../api/ai/materialType";
import { analysisUrl } from "../../api/ai/videoCarry";
import aiUploadPic from "@/components/aiUploadPic/index.vue";
import MaterialDrawer from "@/components/MaterialDrawer/index.vue";
export default {
  name: "VideoCarry",
  components: { historyDrawer, Treeselect, aiUploadPic, MaterialDrawer },
  dicts: [
    "source_speech_language",
    "target_speech_language",
    "source_text_language",
    "target_text_language",
    "source_face_language",
    "target_face_language",
  ],
  data() {
    return {
      quota: {
        usePublishCount: 0,
        publishCount: 100,
      },
      textareaUrl: "",
      loading: false,
      publishVisible: false,
      buttonLoading: false,
      accountOptions: [],
      startAnalysisLoading: false,
      ruleForm: {
        account: [],
        postContent: "",
      },
      analysisUrls: [],
      // analysisUrls: [
      //   'https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/111.mp4',
      //   'http://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/2024/12/06/b3b0a469a65d4577845bb862586bfeef.mp4',
      //   'https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/111.mp4',
      //   'http://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/2024/12/06/b3b0a469a65d4577845bb862586bfeef.mp4',
      //   'https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/111.mp4',
      //   'http://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/2024/12/06/b3b0a469a65d4577845bb862586bfeef.mp4'],
      taskList: [],
      dialogPlay: false,
      playUrl: "",
      timer: null,
      releaseUrl: "",
      rules: {
        postContent: [
          {
            required: true,
            message: this.$t("videoCarry.PleaseEnterText"),
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            message: this.$t("videoCarry.selectAccount"),
            trigger: "change",
          },
        ],
      },
      drawerHistory: false,
      treeselectId: "material-0", //绑定treeselect的值 格式为：material-0、 product-0
      positionList: [],
      folderId: "0",
      saveTo: "material",
      isAnalysis: false, //是否已经解析
      videoUrl: "", //解析之后的url
      videoForm: {
        jobProgressList: [],
        jobProgress: "",
        mergeFace: null,
        eraseSubtitle: null,
        videoTranslation: null,
      }, //需要提交的参数
      videoTranslation: {
        needSpeechTranslate: false,
        needSubtitleTranslate: true,
        needFaceTranslate: false,
        sourceLanguage: "",
        targetLanguage: "",
        bilingualSubtitle: true,
        // saveTo: "product",
        mediaList: [],
      },
      facePicUrl: "",
      drawer: false, //素材选择的抽屉
    };
  },
  watch: {
    treeselectId(newVal) {
      const [type, id] = newVal.split("-");
      this.folderId = id;
      this.saveTo = type;
      console.log("选中的文件夹:", type);
      console.log("所属类别:", id); // 这里可以拿到是 product 还是 material
    },
  },
  async created() {
    // this.getTaskList();
    this.getAccountList();
    await this.getMaterialTypeList();
    await this.getProductTypeList();
  },
  methods: {
    chooseMaterial(num) {
      this.drawer = true;
      let type;
      if (num === 2) {
        type = 2;
      } else if (num === 3 || num === 4 || num === 5) {
        type = 3;
      }
      this.$nextTick().then((rs) => {
        this.$refs.materialDrawer.$refs.materialLibrary.init(type);
      });
    },
    selectionConfirmed(items) {
      this.facePicUrl = items[0].url;
      // 这里可以执行进一步的处理，例如保存到数据中等
      this.drawer = false;
      // this.handleItemUpdated()
    },
    chooseSystemPic(url) {
      this.facePicUrl = url;
    },
    showLocaPic4(items) {
      this.facePicUrl = items[0].url;
    },
    removeFacePic() {
      this.facePicUrl = "";
    },
    // 视频翻译的类型切换
    changeType(e) {
      this.videoTranslation.sourceLanguage = "";
      this.videoTranslation.targetLanguage = "";
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      const countLabel =
        node.type === "product"
          ? node.productCount > 0
            ? `  (${node.productCount})`
            : ""
          : node.materialCount > 0
          ? `  (${node.materialCount})`
          : "";
      return {
        id: `${node.type}-${node.id}`, // 避免 ID 冲突
        label: `${node.name}${countLabel}`,
        type: node.type, // 记录类型
        children: node.children,
      };
    },
    loadOptions(node) {
      // 如果节点没有子节点，返回空数组,此接口可以添加检索功能检索是否有子节点
      if (!node || !node.id) {
        node.callback([]);
        return;
      }
      node.callback([]);
    },
    async getMaterialTypeList() {
      listMaterialType().then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          const materialTypes = response.data
            .filter((item) => item.type !== "system")
            .map((item) => ({
              ...item,
              type: "material", // 添加 type 识别
            }));
          this.positionList.push({
            id: 0,
            name: this.$t("basicInfo.materia"),
            type: "material",
            children: materialTypes,
          });
        }
      });
    },
    //成片分组列表
    async getProductTypeList() {
      listProductType().then((response) => {
        const productTypes = response.rows.map((item) => ({
          ...item,
          type: "product", // 添加 type 识别
        }));
        this.positionList.push({
          id: 0,
          name: this.$t("product.product"),
          type: "product",
          children: productTypes,
        });
      });
    },
    closeHistory() {
      this.drawerHistory = false;
    },
    openHistory() {
      this.drawerHistory = true;
    },
    filterTime(time) {
      const datePart = time.split(" ")[0];
      return datePart.replace(/-/g, "/");
    },
    filterPlatFormIcon(name) {
      let icon;
      switch (name) {
        case "youtube":
          icon = "icon-youtube-normal";
          break;
        case "tiktok":
          icon = "icon-tiktok-normal";
          break;
        case "facebook":
          icon = "icon-facebook-normal";
          break;
        case "linkedin":
          icon = "icon-linkedin-normal";
          break;
        case "twitter":
          icon = "icon-twitter-normal";
          break;
        case "instagram":
          icon = "icon-instagram-normal";
          break;
      }
      return icon;
    },
    async getAccountList() {
      try {
        // this.loading = true
        const { data } = await listAllAccount({ platform: "" });
        this.accountOptions = data;
        // this.loading = false
      } catch (e) {
        console.log(e);
        // this.loading = false
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.buttonLoading = true;
            await releasePublish({
              list: [
                {
                  postContent: this.ruleForm.postContent,
                  publishTimeType: 0,
                  socialIdsByTranslate: [
                    {
                      text: this.ruleForm.postContent,
                      socialIds: this.ruleForm.account,
                    },
                  ],
                  url: this.releaseUrl,
                },
              ],
            });
            this.buttonLoading = false;
            this.publishVisible = false;
            this.analysisUrls = this.analysisUrls.filter(
              (item) => item !== this.releaseUrl
            );
            this.$message({
              type: "success",
              message: this.$t("videoCarry.successTip"),
              duration: 1500,
            });
            await this.getTaskList();
          } catch (e) {
            console.log(e);
            this.buttonLoading = false;
            this.$message({
              type: "warning",
              message: this.$t("videoCarry.errorTip"),
              duration: 1500,
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    openPublish(url) {
      this.releaseUrl = url;
      this.publishVisible = true;
    },
    handleBeforeClose() {
      this.publishVisible = false;
    },
    async getTaskList() {
      try {
        this.loading = true;
        const data = await getTaskList({ jobType: "transport" });
        this.taskList = data.rows || [];
        if (this.taskList.some((item) => item.status == 1)) {
          // 如果定时器不存在，启动定时器
          if (!this.timer) {
            this.timer = setInterval(() => {
              this.getTaskList();
            }, 30000); // 每30秒调用一次
          }
        } else {
          this.clearTimer();
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    async startAnalysis() {
      if (this.textareaUrl) {
        if (this.textareaUrl && !this.textareaUrl.includes("v.douyin.com")) {
          this.$modal.msgError(this.$t("videoCarry.videoLinkError"));
          return;
        }
        try {
          this.startAnalysisLoading = true;
          const { data } = await analysisUrl({
            url: this.textareaUrl,
            // saveTo: this.saveTo,
            // folderId: this.folderId,
          });
          if (data.cover) {
            this.isAnalysis = true;
            this.videoUrl = data.playUrl;
            this.resetVideoForm();
            this.videoForm = { ...this.videoForm, ...data };
            this.videoForm.sourceUrl = this.textareaUrl;
            console.log("this.videoForm", this.videoForm);
            // data.sourceUrl = this.textareaUrl;
            // sessionStorage.setItem("videoCarryData", JSON.stringify(data));
            // this.$tab.closeOpenPage({
            //   path: `/issueVideo`,
            //   query: {
            //     isCarry: true,
            //   },
            // });
            // this.$router.push({
            //   path: `/issueVideo`,
            //   query: {
            //     isCarry: true,
            //   },
            // });
          }
          this.startAnalysisLoading = false;
        } catch (e) {
          console.log(e);
          this.startAnalysisLoading = false;
        }
      } else {
        this.$message({
          type: "warning",
          message: this.$t("videoCarry.pleaseVideoLink"),
          duration: 1500,
        });
      }
    },
    goToPublish() {
      sessionStorage.setItem("videoCarryData", JSON.stringify(this.videoForm));
      this.$tab.closeOpenPage({
        path: `/issueVideo`,
        query: {
          isCarry: true,
        },
      });
    },
    removeMaterial() {
      this.videoUrl = "";
      this.isAnalysis = false;
      this.resetVideoForm();
    },
    resetVideoForm() {
      this.videoForm = {
        jobProgressList: [],
        jobProgress: "",
        mergeFace: null,
        eraseSubtitle: null,
        videoTranslation: null,
      };
    },
    playVideo(url) {
      this.playUrl = url;
      this.dialogPlay = true;
      // 播放视频时获取视频元素
      this.$nextTick(() => {
        this.videoElement = this.$refs.videoPlayer;
        this.videoElement.play();
      });
    },
    clearTextareaUrl() {
      this.textareaUrl = "";
    },
    pasteTextareaUrl() {
      try {
        if (navigator.clipboard) {
          navigator.clipboard
            .readText()
            .then((clipboardText) => {
              this.textareaUrl = clipboardText;
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: this.$t("videoCarry.pasteTip"),
                duration: 1500,
              });
            });
        } else {
          this.$message({
            type: "warning",
            message: this.$t("videoCarry.pasteTip"),
            duration: 1500,
          });
        }
      } catch (e) {
        this.$message({
          type: "warning",
          message: this.$t("videoCarry.pasteTip"),
          duration: 1500,
        });
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.videoCarry {
  padding: 24px;
  .faceContent {
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    .textareaStyle {
      ::v-deep .el-textarea__inner {
        width: 100%;
        height: 140px;
        margin: auto;
        background: #f6f8fa;
        border-radius: 12px;
        border: 1px solid rgba(179, 179, 179, 0.38);
      }
    }
    .btnBox {
      width: 100%;
      margin-top: 32px;
      position: relative;
      .el-button {
        width: 140px;
      }
      .position-box {
        position: absolute;
        right: 0;
        width: 250px;
      }
    }
  }
  .videoContent {
    width: 70%;
    height: 520px;
    padding: 16px;
    background: #f6f8fa;
    border-radius: 12px;
    border: 2px dashed rgba(179, 179, 179, 0.38);
    position: relative;
    .videoBox {
      position: relative;
      width: 275.3px;
      height: 488px;
      background: #000000;
      border-radius: 12px;
      video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
      }
    }
    .deleteIcon {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 28px;
      height: 28px;
      background: #000000;
      border-radius: 8px;
      opacity: 0.5;
      cursor: pointer;
      justify-content: center;
      display: flex;
      align-items: center;
      .icon-delete {
        color: #fff;
        font-size: 16px;
      }
    }
  }
  .setContent {
    width: 70%;
    padding: 20px;
    .vt-tip {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.4);
      line-height: 22px;
      text-align: left;
      font-style: normal;
      margin-top: 16px;
      display: flex;
    }
    .icon-zhushi {
      font-size: 14px;
      margin-right: 4px;
    }
    .addFaceBox {
      margin-top: 34px;
      .add-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
        line-height: 20px;
        text-align: left;
        font-style: normal;
        margin-bottom: 8px;
      }
      .uploadFace {
        display: flex;
      }
      .facePicBox {
        width: 180px;
        height: 293px;
        border-radius: 12px;
        overflow: hidden;
        margin-left: 24px;
        position: relative;
        img {
          width: 180px;
          height: 293px;
          object-fit: cover;
        }
        .deleteIcon {
          position: absolute;
          top: 8px;
          right: 8px;
          width: 28px;
          height: 28px;
          background: #000000;
          border-radius: 8px;
          opacity: 0.5;
          cursor: pointer;
          justify-content: center;
          display: flex;
          align-items: center;
          .icon-delete {
            color: #fff;
            font-size: 16px;
          }
        }
      }
    }
  }
  .publicDialog {
    .dialogContent {
      padding: 24px;
    }
    .dialog-footer {
      margin-top: 96px;
      .el-button {
        width: 120px;
        height: 40px;
      }
    }
    .el-dialog__body {
      display: none;
    }
  }
  .icon-youtube-normal {
    color: #db0200;
  }
  .icon-tiktok-normal {
    background: #333333;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .icon-facebook-normal {
    color: #1a77f3;
  }
  .icon-linkedin-normal {
    color: #0474b3;
  }
  .icon-twitter-normal {
    color: #004da5;
  }
  .icon-instagram-normal {
    background: linear-gradient(221deg, #d82a81 0%, #ffcb5c 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.el-form-item {
  margin-bottom: 5px;
}

.el-form--label-top .el-form-item__label {
  padding: 0;
}
</style>
