<!-- 视频采集——（原）视频搬运 -->
<template>
  <div class="videoGather">
    <div class="faceContent">
      <el-popover
        placement="top"
        width="400"
        trigger="hover"
      >
        <template>
          <span v-html="$t('videoCarry.pleaseVideoLinkDy')"></span>
        </template>
        <el-input
          slot="reference"
          class="textareaStyle"
          type="textarea"
          :rows="2"
          :placeholder="$t('videoCarry.pleaseVideoLink')"
          resize="none"
          v-model="textareaUrl"
        >
        </el-input>
      </el-popover>
      <div class="btnBox flex-center">
        <el-button
          class="button-white"
          @click="clearTextareaUrl"
        >{{ $t('videoCarry.clearContent') }}</el-button>
        <el-button
          class="normal-button"
          @click="pasteTextareaUrl"
        >{{ $t('videoCarry.clickPaste') }}</el-button>
        <el-button
          class="gradient-button"
          @click="startAnalysis"
          :loading="startAnalysisLoading"
        >{{ $t('videoCarry.startParsing') }}</el-button>
        <div class="position-box flex-row-center">
          <span class="f-shrink">{{ $t('videoTranslationPage.saveto') }}：</span>
          <treeselect
            v-model="treeselectId"
            :options="positionList"
            :normalizer="normalizer"
            :load-options="loadOptions"
            :clearable="false"
            :placeholder="$t('file.tipSelectFolder')"
          >
            <template #value-label="{ node }">
              {{ node.raw.name }}
            </template>
          </treeselect>
        </div>
      </div>
      <!-- <div
        class="videoContent"
        v-loading="loading"
      >
        <div
          class="makeVideoItem"
          v-for="(item, index) in analysisUrls"
          :key="index"
        >
          <div class="material-container">
            <video
              :src="item"
              class="videoBox"
            ></video>
            <div
              class="cricle"
              @click="playVideo(item)"
            >
              <i class="el-icon-caret-right"></i>
            </div>
            <div
              class="deleteIcon"
              @click="removeMaterial(index)"
            >
              <i class="iconfont icon-delete"></i>
            </div>
          </div>
          <el-button
            class="gradient-button"
            @click="openPublish(item)"
          >{{ $t('videoCarry.publishVideo') }}</el-button>
        </div>
        <div
          class="videoItem"
          v-for="item in taskList"
          :key="item.id"
        >
          <div class="material-container">
            <video
              :src="item.finishedUrl"
              class="videoBox"
            ></video>
            <div
              class="cricle"
              @click="playVideo(item.finishedUrl)"
            >
              <i class="el-icon-caret-right"></i>
            </div>
            <div class="userInfo">
              <div class="leftUser">
                <img
                  :src="item.avatarUrl"
                  alt=""
                  v-if="item.avatarUrl"
                >
                <div
                  class="nameHidden"
                  v-if="item.displayName"
                >
                  {{ item.displayName }}
                </div>
              </div>
              <div class="rightTime">
                {{ filterTime(item.createTime) }}
              </div>
            </div>
          </div>
          <div class="videoTitle">
            {{ item.postContent }}
          </div>
          <div class="flexState">
            <div class="leftIcon">
              <div class="iconBox">
                <i class="iconfont icon-tiktok-normal"></i>
              </div>
              <svg-icon
                icon-class="loading"
                class="loadingIcon"
              ></svg-icon>
              <div class="iconBox">
                <i :class="['iconfont', filterPlatFormIcon(item.socialType)]"></i>
              </div>
            </div>
            <div :class="['rightState', item.status === 2 ? 'success' : item.status === 1 ? 'pending' : 'error']">
              {{ item.status === 2 ? $t('issueManager.published') : item.status === 1 ? $t('issueManager.publishing') : $t('issueManager.failed') }}
            </div>
          </div>
        </div>
      </div> -->
      <historyDrawer
        ref="historyDrawer"
        type="gather"
        :drawerHistory="drawerHistory"
        @closeHistory="closeHistory"
      />
    </div>
    <el-dialog
      v-if="dialogPlay"
      :visible.sync="dialogPlay"
      width="50%"
    >
      <video
        controls
        :src="playUrl"
        class="video-player"
        ref="videoPlayer"
      ></video>
    </el-dialog>
    <el-dialog
      class="publicDialog"
      width="50%"
      v-if="publishVisible"
      :visible.sync="publishVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleBeforeClose"
      :show-close="false"
    >
      <div slot="title">
        <div class="headerFlex">
          <div class="headerTitle">{{ $t('videoCarry.editingCopy') }}</div>
          <i
            class="el-icon-close"
            @click="handleBeforeClose"
          ></i>
        </div>
        <div class="dialogContent">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <el-form-item prop="postContent">
              <el-input
                :placeholder="$t('videoCarry.PleaseEnterText')"
                type="textarea"
                :rows="4"
                resize="none"
                v-model="ruleForm.postContent"
              ></el-input>
            </el-form-item>
            <el-form-item prop="account">
              <el-select
                style="width: 100%;"
                v-model="ruleForm.account"
                multiple
                :placeholder="$t('videoCarry.selectAccount')"
              >
                <el-option
                  v-for="item in accountOptions"
                  :key="item.id"
                  :label="item.username"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div
            slot="footer"
            class="dialog-footer"
          >
            <el-button
              class="cancelBtn"
              @click="handleBeforeClose"
            >{{ $t('cancel') }}</el-button>
            <el-button
              class="submitBtn"
              :loading="buttonLoading"
              @click="submitForm('ruleForm')"
            >{{ $t('videoManager.publish') }}</el-button>
          </div>
          <!-- <el-input
          class="textareaStyle"
          type="textarea"
          :rows="2"
          placeholder="请输入视频链接"
          resize="none"
          v-model="textareaUrl">
        </el-input> -->
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { releasePublish } from "../../api/ai/publish";
import { listAllAccount } from "../../api/account/tiktok";
import { getTaskList } from "../../api/ai/aiFacewarp";
import historyDrawer from "./components/historyDrawer.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { listProductType } from "../../api/ai/productType";
import { listMaterialType } from "../../api/ai/materialType";
import { analysisUrl, videoGather } from "../../api/ai/videoCarry";
export default {
  name: "VideoGather",
  components: { historyDrawer, Treeselect },
  data() {
    return {
      quota: {
        usePublishCount: 0,
        publishCount: 100,
      },
      textareaUrl: "",
      loading: false,
      publishVisible: false,
      buttonLoading: false,
      accountOptions: [],
      startAnalysisLoading: false,
      ruleForm: {
        account: [],
        postContent: "",
      },
      analysisUrls: [],
      // analysisUrls: [
      //   'https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/111.mp4',
      //   'http://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/2024/12/06/b3b0a469a65d4577845bb862586bfeef.mp4',
      //   'https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/111.mp4',
      //   'http://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/2024/12/06/b3b0a469a65d4577845bb862586bfeef.mp4',
      //   'https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/111.mp4',
      //   'http://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com/2024/12/06/b3b0a469a65d4577845bb862586bfeef.mp4'],
      taskList: [],
      dialogPlay: false,
      playUrl: "",
      timer: null,
      releaseUrl: "",
      rules: {
        postContent: [
          {
            required: true,
            message: this.$t("videoCarry.PleaseEnterText"),
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            message: this.$t("videoCarry.selectAccount"),
            trigger: "change",
          },
        ],
      },
      drawerHistory: false,
      treeselectId: "material-0", //绑定treeselect的值 格式为：material-0、 product-0
      positionList: [],
      folderId: "0",
      saveTo: "material",
    };
  },
  watch: {
    treeselectId(newVal) {
      const [type, id] = newVal.split("-");
      this.folderId = id;
      this.saveTo = type;
      console.log("选中的文件夹:", type);
      console.log("所属类别:", id); // 这里可以拿到是 product 还是 material
    },
  },
  async created() {
    // this.getTaskList();
    this.getAccountList();
    await this.getMaterialTypeList();
    await this.getProductTypeList();
  },
  methods: {
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      const countLabel =
        node.type === "product"
          ? node.productCount > 0
            ? `  (${node.productCount})`
            : ""
          : node.materialCount > 0
          ? `  (${node.materialCount})`
          : "";
      return {
        id: `${node.type}-${node.id}`, // 避免 ID 冲突
        label: `${node.name}${countLabel}`,
        type: node.type, // 记录类型
        children: node.children,
      };
    },
    loadOptions(node) {
      // 如果节点没有子节点，返回空数组,此接口可以添加检索功能检索是否有子节点
      if (!node || !node.id) {
        node.callback([]);
        return;
      }
      node.callback([]);
    },
    async getMaterialTypeList() {
      listMaterialType().then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          const materialTypes = response.data
            .filter((item) => item.type !== "system")
            .map((item) => ({
              ...item,
              type: "material", // 添加 type 识别
            }));
          this.positionList.push({
            id: 0,
            name: this.$t("basicInfo.materia"),
            type: "material",
            children: materialTypes,
          });
        }
      });
    },
    //成片分组列表
    async getProductTypeList() {
      listProductType().then((response) => {
        const productTypes = response.rows.map((item) => ({
          ...item,
          type: "product", // 添加 type 识别
        }));
        this.positionList.push({
          id: 0,
          name: this.$t("product.product"),
          type: "product",
          children: productTypes,
        });
      });
    },
    closeHistory() {
      this.drawerHistory = false;
    },
    openHistory() {
      this.drawerHistory = true;
    },
    filterTime(time) {
      const datePart = time.split(" ")[0];
      return datePart.replace(/-/g, "/");
    },
    filterPlatFormIcon(name) {
      let icon;
      switch (name) {
        case "youtube":
          icon = "icon-youtube-normal";
          break;
        case "tiktok":
          icon = "icon-tiktok-normal";
          break;
        case "facebook":
          icon = "icon-facebook-normal";
          break;
        case "linkedin":
          icon = "icon-linkedin-normal";
          break;
        case "twitter":
          icon = "icon-twitter-normal";
          break;
        case "instagram":
          icon = "icon-instagram-normal";
          break;
      }
      return icon;
    },
    async getAccountList() {
      try {
        // this.loading = true
        const { data } = await listAllAccount({ platform: "" });
        this.accountOptions = data;
        // this.loading = false
      } catch (e) {
        console.log(e);
        // this.loading = false
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.buttonLoading = true;
            await releasePublish({
              list: [
                {
                  postContent: this.ruleForm.postContent,
                  publishTimeType: 0,
                  socialIdsByTranslate: [
                    {
                      text: this.ruleForm.postContent,
                      socialIds: this.ruleForm.account,
                    },
                  ],
                  url: this.releaseUrl,
                },
              ],
            });
            this.buttonLoading = false;
            this.publishVisible = false;
            this.analysisUrls = this.analysisUrls.filter(
              (item) => item !== this.releaseUrl
            );
            this.$message({
              type: "success",
              message: this.$t("videoCarry.successTip"),
              duration: 1500,
            });
            await this.getTaskList();
          } catch (e) {
            console.log(e);
            this.buttonLoading = false;
            this.$message({
              type: "warning",
              message: this.$t("videoCarry.errorTip"),
              duration: 1500,
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    openPublish(url) {
      this.releaseUrl = url;
      this.publishVisible = true;
    },
    handleBeforeClose() {
      this.publishVisible = false;
    },
    async getTaskList() {
      try {
        this.loading = true;
        const data = await getTaskList({ jobType: "transport" });
        this.taskList = data.rows || [];
        if (this.taskList.some((item) => item.status == 1)) {
          // 如果定时器不存在，启动定时器
          if (!this.timer) {
            this.timer = setInterval(() => {
              this.getTaskList();
            }, 30000); // 每30秒调用一次
          }
        } else {
          this.clearTimer();
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    async startAnalysis() {
      if (this.textareaUrl) {
        if (this.textareaUrl && !this.textareaUrl.includes("v.douyin.com")) {
          this.$modal.msgError(this.$t("videoCarry.videoLinkError"));
          return;
        }
        try {
          this.startAnalysisLoading = true;
          const { data } = await analysisUrl({
            url: this.textareaUrl,
          });
          if (data.cover) {
            videoGather({
              cover: data.cover,
              url: data.url,
              saveTo: this.saveTo,
              folderId: this.folderId,
              videoName: data.title,
            }).then((res) => {
              if (res.data && res.data.finishedUrl) {
                this.analysisUrls.push(data.finishedUrl);
                this.$modal.msgSuccess(this.$t("videoCarry.parsingSuccess"));
                this.$nextTick().then((rs) => {
                  this.$refs.historyDrawer.getList();
                });
              }
              this.startAnalysisLoading = false;
            });
          }
        } catch (e) {
          console.log(e);
          this.startAnalysisLoading = false;
        }
      } else {
        this.$message({
          type: "warning",
          message: this.$t("videoCarry.pleaseVideoLink"),
          duration: 1500,
        });
      }
    },
    removeMaterial(index) {
      this.analysisUrls.splice(index, 1);
    },
    playVideo(url) {
      this.playUrl = url;
      this.dialogPlay = true;
      // 播放视频时获取视频元素
      this.$nextTick(() => {
        this.videoElement = this.$refs.videoPlayer;
        this.videoElement.play();
      });
    },
    clearTextareaUrl() {
      this.textareaUrl = "";
    },
    pasteTextareaUrl() {
      try {
        if (navigator.clipboard) {
          navigator.clipboard
            .readText()
            .then((clipboardText) => {
              this.textareaUrl = clipboardText;
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: this.$t("videoCarry.pasteTip"),
                duration: 1500,
              });
            });
        } else {
          this.$message({
            type: "warning",
            message: this.$t("videoCarry.pasteTip"),
            duration: 1500,
          });
        }
      } catch (e) {
        this.$message({
          type: "warning",
          message: this.$t("videoCarry.pasteTip"),
          duration: 1500,
        });
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.videoGather {
  padding: 24px;
  .faceContent {
    height: calc(100vh - 120px);
    background: #ffffff;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    .textareaStyle {
      ::v-deep .el-textarea__inner {
        width: 100%;
        height: 140px;
        margin: auto;
        background: #f6f8fa;
        border-radius: 12px;
        border: 1px solid rgba(179, 179, 179, 0.38);
      }
    }
    .btnBox {
      width: 100%;
      margin-top: 32px;
      position: relative;
      .el-button {
        width: 140px;
      }
      .position-box {
        position: absolute;
        right: 0;
        width: 250px;
      }
    }
    .videoContent {
      margin-top: 120px;
      display: flex;
      flex-wrap: wrap;
      gap: 37px;
      .makeVideoItem {
        width: 240px;
        height: 474px;
        background: #ffffff;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .material-container {
          width: 220px;
          height: 346px;
          position: relative;
          .videoBox {
            width: 220px;
            height: 346px;
            border-radius: 12px;
            background: #000000;
          }
          .cricle {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 54px;
            height: 54px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.5);
            cursor: pointer;
            .el-icon-caret-right {
              color: #fff;
              font-size: 35px;
            }
          }
          .deleteIcon {
            position: absolute;
            top: 8px;
            right: 8px;
            width: 28px;
            height: 28px;
            background: #000000;
            border-radius: 8px;
            opacity: 0.5;
            cursor: pointer;
            justify-content: center;
            display: flex;
            align-items: center;
            .icon-delete {
              color: #fff;
              font-size: 16px;
            }
          }
        }
      }
      .videoItem {
        width: 240px;
        height: 474px;
        background: #ffffff;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding: 10px;
        .material-container {
          width: 220px;
          height: 346px;
          position: relative;
          .videoBox {
            width: 220px;
            height: 346px;
            border-radius: 12px;
            background: #000000;
          }
          .cricle {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 54px;
            height: 54px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.5);
            cursor: pointer;
            .el-icon-caret-right {
              color: #fff;
              font-size: 35px;
            }
          }
          .userInfo {
            width: 204px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            bottom: 8px;
            left: 8px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            line-height: 16px;
            text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
            text-align: left;
            font-style: normal;
            .leftUser {
              width: 150px;
              display: flex;
              align-items: center;
              .nameHidden {
                width: 80px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              img {
                width: 24px;
                height: 24px;
                border: 1px solid #ffffff;
                border-radius: 50%;
                margin-right: 6px;
              }
            }
          }
        }
        .videoTitle {
          margin-top: 10px;
          width: 220px;
          height: 44px;
          font-family: PingFang-SC, PingFang-SC;
          font-weight: bold;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.9);
          line-height: 22px;
          text-align: left;
          font-style: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-clamp: 2;
        }
        .flexState {
          display: flex;
          justify-content: space-between;
          margin-top: 16px;
          .leftIcon {
            display: flex;
            align-items: center;
            .iconBox {
              width: 24px;
              height: 24px;
              background: #f3f3f3;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              .iconfont {
                font-size: 13px;
              }
            }
            .loadingIcon {
              width: 26px;
              height: 6px;
              margin: 0 8px;
            }
          }
          .rightState {
            width: 60px;
            height: 24px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            line-height: 18px;
            text-align: left;
            font-style: normal;
          }
          .success {
            background: #016fee;
          }
          .pending {
            background: #eeaa01;
          }
          .error {
            background: #e34d59;
          }
        }
      }
    }
  }
  .publicDialog {
    .dialogContent {
      padding: 24px;
    }
    .dialog-footer {
      margin-top: 96px;
      .el-button {
        width: 120px;
        height: 40px;
      }
    }
    .el-dialog__body {
      display: none;
    }
  }
  .icon-youtube-normal {
    color: #db0200;
  }
  .icon-tiktok-normal {
    background: #333333;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .icon-facebook-normal {
    color: #1a77f3;
  }
  .icon-linkedin-normal {
    color: #0474b3;
  }
  .icon-twitter-normal {
    color: #004da5;
  }
  .icon-instagram-normal {
    background: linear-gradient(221deg, #d82a81 0%, #ffcb5c 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
