var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unsubtitle" },
    [
      _c("div", { staticClass: "whiteContent" }, [
        _c("div", { staticClass: "faceContent flex-column" }, [
          _c(
            "div",
            { staticClass: "leftUpload flex-center" },
            [
              !_vm.selectVideoList.length
                ? _c("ai-uploadFile", {
                    attrs: { initNum: 6, fileType: _vm.fileType },
                    on: {
                      selectMaterial: _vm.selectMaterial,
                      showLocaMedia: _vm.showLocaMedia,
                    },
                  })
                : _vm._e(),
              _vm.videoUrl && _vm.subtitleWay === "1"
                ? _c("boxSelectCanvas", {
                    ref: "boxSelectCanvas",
                    attrs: { videoUrl: _vm.videoUrl },
                  })
                : _vm._e(),
              _vm.videoUrl && _vm.subtitleWay === "2"
                ? _c("div", { staticClass: "videoBox" }, [
                    _c("video", { ref: "video", attrs: { src: _vm.videoUrl } }),
                  ])
                : _vm._e(),
              _vm.videoUrl
                ? _c(
                    "div",
                    {
                      staticClass: "deleteIcon",
                      on: { click: _vm.removeMaterial },
                    },
                    [_c("i", { staticClass: "iconfont icon-delete" })]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "rightForm flex-column" },
            [
              _c("div", { staticClass: "formTitle" }, [
                _c("div", { staticClass: "r-title" }, [
                  _vm._v(_vm._s(_vm.$t("unsubtitle.setting"))),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "addFaceBox" },
                [
                  _c("div", { staticClass: "add-title" }, [
                    _vm._v(_vm._s(_vm.$t("unsubtitle.removeSubtitles")) + ":"),
                  ]),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.subtitleWay,
                        callback: function ($$v) {
                          _vm.subtitleWay = $$v
                        },
                        expression: "subtitleWay",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1", border: "" } }, [
                        _vm._v(_vm._s(_vm.$t("unsubtitle.selectArea"))),
                      ]),
                      _c(
                        "el-radio",
                        {
                          staticClass: "marginRadio",
                          attrs: { label: "2", border: "" },
                        },
                        [_vm._v(_vm._s(_vm.$t("unsubtitle.fullScreen")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "selectSaveType" },
                [
                  _c("div", { staticClass: "loc-title" }, [
                    _vm._v(_vm._s(_vm.$t("faceSwap.storageLocation")) + ":"),
                  ]),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.saveTo,
                        callback: function ($$v) {
                          _vm.saveTo = $$v
                        },
                        expression: "saveTo",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "material" } }, [
                        _vm._v(_vm._s(_vm.$t("videoTranslationPage.material"))),
                      ]),
                      _c("el-radio", { attrs: { label: "product" } }, [
                        _vm._v(_vm._s(_vm.$t("videoTranslationPage.work"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "gradient-button self-center",
                  staticStyle: { margin: "25px 0", width: "200px" },
                  attrs: { loading: _vm.submitLoading },
                  on: { click: _vm.submitUnsubtitle },
                },
                [_vm._v(_vm._s(_vm.$t("unsubtitle.clickSubtitleRemoval")))]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm.dialogPlay
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.dialogPlay, width: "50%" },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogPlay = $event
                },
              },
            },
            [
              _c("video", {
                ref: "videoPlayer",
                staticClass: "video-player",
                attrs: { controls: "", src: _vm.playUrl },
              }),
            ]
          )
        : _vm._e(),
      _c("historyDrawer", {
        ref: "historyDrawer",
        attrs: { type: "eraseSubtitle", drawerHistory: _vm.drawerHistory },
        on: { closeHistory: _vm.closeHistory },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }