var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "faceSwap" },
    [
      _c("div", { staticClass: "flex-center w-100" }, [
        _c("div", { staticClass: "flex-row faceContent" }, [
          _c("div", { staticClass: "leftUpload" }, [
            _c(
              "div",
              { staticClass: "centerUpload flex-center" },
              [
                _vm._l(_vm.selectVideoList, function (material, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "aspect-ratio-box flex-column" },
                    [
                      _c("div", { staticClass: "material-container" }, [
                        _c("video", {
                          staticClass: "material-thumbnail",
                          attrs: { src: material.url },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "cricle",
                            on: {
                              click: function ($event) {
                                return _vm.playVideo(material.url)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-caret-right" })]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "deleteIcon",
                            on: {
                              click: function ($event) {
                                return _vm.removeMaterial(index)
                              },
                            },
                          },
                          [_c("i", { staticClass: "iconfont icon-delete" })]
                        ),
                      ]),
                      material.fileType === "video" && material.videoLength
                        ? _c("div", { staticClass: "video-duration" }, [
                            _vm._v(
                              _vm._s(
                                _vm.secondsFormatTime(material.videoLength)
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                !_vm.selectVideoList.length
                  ? _c("ai-uploadFile", {
                      attrs: { fileType: _vm.fileType, initNum: 6 },
                      on: {
                        selectMaterial: _vm.selectMaterial,
                        showLocaMedia: _vm.showLocaMedia,
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _c(
            "div",
            { staticClass: "rightForm flex-column" },
            [
              _c("div", { staticClass: "f-grow" }, [
                _c("div", { staticClass: "formTitle" }, [
                  _c("div", { staticClass: "fs-title" }, [
                    _vm._v(
                      _vm._s(_vm.$t("videoTranslationPage.videoSettings"))
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "vt-tip" }, [
                  _c("i", { staticClass: "iconfont icon-zhushi" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("faceSwap.tip")) +
                      ", " +
                      _vm._s(_vm.$t("file.invalidResolution")) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "addFaceBox" }, [
                  _c("div", { staticClass: "add-title" }, [
                    _vm._v(_vm._s(_vm.$t("faceSwap.uploadFaceShape")) + ":"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "uploadFace" },
                    [
                      !_vm.facePicUrl
                        ? _c("aiUploadPic", {
                            on: {
                              picSelectMaterial: function ($event) {
                                return _vm.chooseMaterial(4)
                              },
                              picSelectSystem: _vm.chooseSystemPic,
                              showLocaPic: _vm.showLocaPic4,
                            },
                          })
                        : _vm._e(),
                      _vm.facePicUrl
                        ? _c("div", { staticClass: "facePicBox" }, [
                            _c("img", {
                              attrs: { src: _vm.facePicUrl, alt: "" },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "deleteIcon",
                                on: { click: _vm.removeFacePic },
                              },
                              [_c("i", { staticClass: "iconfont icon-delete" })]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "selectSaveType" },
                  [
                    _c("div", { staticClass: "loc-title" }, [
                      _vm._v(_vm._s(_vm.$t("faceSwap.storageLocation")) + ":"),
                    ]),
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.saveTo,
                          callback: function ($$v) {
                            _vm.saveTo = $$v
                          },
                          expression: "saveTo",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "material" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("videoTranslationPage.material"))
                          ),
                        ]),
                        _c("el-radio", { attrs: { label: "product" } }, [
                          _vm._v(_vm._s(_vm.$t("videoTranslationPage.work"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-button",
                {
                  staticClass: "gradient-button self-center",
                  staticStyle: { margin: "25px 0", width: "200px" },
                  attrs: { loading: _vm.submitLoading },
                  on: { click: _vm.submitFace },
                },
                [_vm._v(_vm._s(_vm.$t("faceSwap.clickAIface")))]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("material-drawer", {
        ref: "materialDrawer",
        attrs: { visible: _vm.drawer },
        on: {
          "update:visible": function ($event) {
            _vm.drawer = $event
          },
          selectionConfirmed: _vm.selectionConfirmed,
        },
      }),
      _vm.dialogPlay
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.dialogPlay, width: "50%" },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogPlay = $event
                },
              },
            },
            [
              _c("video", {
                ref: "videoPlayer",
                staticClass: "video-player",
                attrs: { controls: "", src: _vm.playUrl },
              }),
            ]
          )
        : _vm._e(),
      _c("historyDrawer", {
        ref: "historyDrawer",
        attrs: { type: "mergeFace", drawerHistory: _vm.drawerHistory },
        on: { closeHistory: _vm.closeHistory },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }