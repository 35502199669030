<!-- 视频翻译 -->
<template>
  <div class="videoTranslation">
    <!-- <div class="title-header">
      <div class="title-header-text">{{ $t('videoTranslationPage.headerTitle') }}</div> -->
    <!-- <div class="RightQuota">
        {{$t('quota.quota')}}：<el-progress style="margin: 0 16px;" :stroke-width="10" :percentage="quota.usePublishCount || 0"></el-progress>{{ quota.usePublishCount || 0}}/{{ quota.publishCount || 100}}{{ $t('videoTranslationPage.strip') }}
      </div> -->
    <!-- </div> -->
    <el-form
      :model="videoForm"
      ref="basicInfoForm"
      width="100%"
      label-position="top"
    >
      <div class="flex-center w-100">
        <div class="flex-row contentHeight">
          <div class="vt-left">
            <el-form-item
              prop="mediaList"
              class="formItemHeight"
            >
              <!-- <div :class="[ videoForm.mediaList.length <= 0 && 'centerUpload', videoForm.mediaList.length > 0 && 'aspect-ratio-box-container']"> -->
              <div class="centerUpload flex-center">
                <div
                  v-for="(material, index) in videoForm.mediaList"
                  :key="index"
                  class="aspect-ratio-box flex-column"
                >
                  <div class="material-container">
                    <video
                      :src="material.url"
                      class="material-thumbnail"
                    ></video>
                    <div
                      class="cricle"
                      @click="playVideo(material.url)"
                    >
                      <i class="el-icon-caret-right"></i>
                    </div>
                    <div
                      class="deleteIcon"
                      @click="removeMaterial(index)"
                    >
                      <i class="iconfont icon-delete"></i>
                    </div>
                  </div>
                  <div
                    class="video-duration"
                    v-if="material.fileType === 'video' && material.videoLength"
                  >{{
                    secondsFormatTime(material.videoLength) }}</div>
                </div>
                <!-- <uploadFile
                v-if="videoForm.mediaList.length"
                :fileType="fileType"
                @selectMaterial="selectMaterial"
                @showLocaMedia="showLocaMedia"
              /> -->
                <!-- <uploadMaterialAndLoca
                v-if="videoForm.mediaList.length"
                :fileType="fileType"
                @selectMaterial="selectMaterial"
                @showLocaMedia="showLocaMedia"
              /> -->
                <!-- v-else -->
                <ai-uploadFile
                  v-if="videoForm.mediaList.length == 0"
                  :fileType="fileType"
                  :initNum="6"
                  @selectMaterial="selectMaterial"
                  @showLocaMedia="showLocaMedia"
                />
              </div>
            </el-form-item>
          </div>
          <div class="vt-right flex-column h-100">
            <div class="formTitle">
              <div class="vt-title">{{ $t('videoTranslationPage.videoSettings') }}</div>
              <!-- <div
                class="history"
                @click="openHistory"
              ><i class="iconfont icon-gongzuotai2"></i>{{ $t('videoTranslationPage.history') }}</div> -->
            </div>
            <div class="vt-tip">
              <i class="iconfont icon-zhushi"></i>
              {{ $t('videoTranslationPage.tip') }}
              <!-- 目前仅支持中文视频的翻译，其中声音仅支持英文的转换，字幕可以转成其他语言 -->
            </div>
            <div class="f-grow">
              <el-form-item
                :label="$t('videoTranslationPage.translationType')"
                style="margin-top: 34px;"
              >
                <!-- <el-radio-group v-model="subtitleOrSpeech" @change="changeType">
              <el-radio label="needSpeechTranslate">{{ $t('videoTranslationPage.sound') }}</el-radio>
              <el-radio label="needSubtitleTranslate">{{ $t('videoTranslationPage.subtitle') }}</el-radio>
            </el-radio-group> -->
                <el-checkbox
                  v-model="videoForm.needSpeechTranslate"
                  @change="changeType"
                >{{
                  $t('videoTranslationPage.sound') }}</el-checkbox>
                <el-checkbox
                  v-model="videoForm.needSubtitleTranslate"
                  @change="changeType"
                >{{
                  $t('videoTranslationPage.subtitle') }}</el-checkbox>
                <el-checkbox
                  v-model="videoForm.needFaceTranslate"
                  @change="changeType"
                >{{
                  $t('videoTranslationPage.face') }}</el-checkbox>
              </el-form-item>
              <el-form-item :label="$t('videoTranslationPage.videoLanguage')">
                <el-select
                  v-if="videoForm.needFaceTranslate"
                  v-model="videoForm.sourceLanguage"
                  :placeholder="$t('tip.select')"
                  clearable
                  filterable
                  :default-first-option="true"
                  class="w-100"
                >
                  <el-option
                    v-for="dict in dict.type.source_face_language"
                    :key="dict.value"
                    :label="dict.label"
                    :value="dict.value"
                  >
                    <span>{{ dict.label }}</span>
                  </el-option>
                </el-select>
                <el-select
                  v-else-if="videoForm.needSubtitleTranslate"
                  v-model="videoForm.sourceLanguage"
                  :placeholder="$t('tip.select')"
                  clearable
                  filterable
                  :default-first-option="true"
                  class="w-100"
                >
                  <el-option
                    v-for="dict in dict.type.source_text_language"
                    :key="dict.value"
                    :label="dict.label"
                    :value="dict.value"
                  >
                    <span>{{ dict.label }}</span>
                  </el-option>
                </el-select>
                <el-select
                  v-else
                  v-model="videoForm.sourceLanguage"
                  :placeholder="$t('tip.select')"
                  clearable
                  filterable
                  :default-first-option="true"
                  class="w-100"
                >
                  <el-option
                    v-for="dict in dict.type.source_speech_language"
                    :key="dict.value"
                    :label="dict.label"
                    :value="dict.value"
                  >
                    <span>{{ dict.label }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('videoTranslationPage.translationLanguage')">
                <el-select
                  v-if="videoForm.needFaceTranslate"
                  v-model="videoForm.targetLanguage"
                  :placeholder="$t('tip.select')"
                  clearable
                  filterable
                  :default-first-option="true"
                  class="w-100"
                >
                  <el-option
                    v-for="dict in dict.type.target_face_language"
                    :key="dict.value"
                    :label="dict.label"
                    :value="dict.value"
                  >
                    <span>{{ dict.label }}</span>
                  </el-option>
                </el-select>
                <el-select
                  v-else-if="videoForm.needSpeechTranslate"
                  v-model="videoForm.targetLanguage"
                  :placeholder="$t('tip.select')"
                  clearable
                  filterable
                  :default-first-option="true"
                  class="w-100"
                >
                  <el-option
                    v-for="dict in dict.type.target_speech_language"
                    :key="dict.value"
                    :label="dict.label"
                    :value="dict.value"
                  >
                    <span>{{ dict.label }}</span>
                  </el-option>
                </el-select>
                <el-select
                  v-else
                  v-model="videoForm.targetLanguage"
                  :placeholder="$t('tip.select')"
                  clearable
                  filterable
                  :default-first-option="true"
                  class="w-100"
                >
                  <el-option
                    v-for="dict in dict.type.target_text_language"
                    :key="dict.value"
                    :label="dict.label"
                    :value="dict.value"
                  >
                    <span>{{ dict.label }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="videoForm.needSubtitleTranslate"
                :label="$t('videoTranslationPage.keepSubtitle')"
              >
                <el-radio
                  v-model="videoForm.bilingualSubtitle"
                  :label="true"
                >{{ $t('videoTranslationPage.keep')
                  }}</el-radio>
                <el-radio
                  v-model="videoForm.bilingualSubtitle"
                  :label="false"
                >{{ $t('videoTranslationPage.discard')
                  }}</el-radio>
              </el-form-item>
              <el-form-item :label="$t('videoTranslationPage.saveto')">
                <el-radio
                  v-model="videoForm.saveTo"
                  label="material"
                >{{ $t('videoTranslationPage.material')
                  }}</el-radio>
                <el-radio
                  v-model="videoForm.saveTo"
                  label="product"
                >{{ $t('videoTranslationPage.work') }}</el-radio>
              </el-form-item>
            </div>
            <el-button
              :loading="submitLoading"
              class="gradient-button self-center"
              @click="makeVideo"
              style="margin: 0 5px;width:200px"
            >{{ $t('videoTranslationPage.clickTranslation') }}</el-button>
          </div>
        </div>
      </div>
    </el-form>
    <!-- 弹窗播放视频 -->
    <el-dialog
      :visible.sync="dialogPlay"
      width="50%"
      :before-close="handleClose"
    >
      <video
        controls
        :src="playUrl"
        class="video-player"
        ref="videoPlayer"
      ></video>
    </el-dialog>

    <!-- v-if="drawerHistory" -->
    <historyDrawer
      ref="historyDrawer"
      type="videoTranslation"
      :drawerHistory="drawerHistory"
      @closeHistory="closeHistory"
    />
  </div>
</template>
<script>
// import uploadFile from "./components/uploadFile.vue";
import historyDrawer from "./components/historyDrawer.vue";
import uploadMaterialAndLoca from "./components/uploadMaterialAndLoca.vue";
import aiUploadFile from "./components/aiUploadFile.vue";
import { videoTranslate, videoTranslation } from "../../api/ai/aiTools";
// import { getLanguagesFromAli } from "../../api/ai/ali";

export default {
  name: "VideoTranslation",
  components: { uploadMaterialAndLoca, aiUploadFile, historyDrawer },
  dicts: [
    "source_speech_language",
    "target_speech_language",
    "source_text_language",
    "target_text_language",
    "source_face_language",
    "target_face_language",
  ],
  data() {
    return {
      quota: {
        usePublishCount: 0,
        publishCount: 100,
      }, //配额
      videoForm: {
        needSpeechTranslate: false,
        needSubtitleTranslate: true,
        needFaceTranslate: false,
        sourceLanguage: "",
        targetLanguage: "",
        bilingualSubtitle: true,
        saveTo: "product",
        mediaList: [],
        ...this.value,
      },
      // subtitleOrSpeech: 'needSpeechTranslate',
      languageList: [],
      submitLoading: false, //一键翻译loading
      fileType: ["mp4", "avi", "mkv", "mov"],
      dialogPlay: false,
      videoElement: null, // 添加一个变量用于保存视频元素
      playUrl: "", // 播放视频的URL
      drawerHistory: false,
    };
  },
  created() {
    this.getLanguage();
  },
  methods: {
    changeType(e) {
      this.videoForm.sourceLanguage = "";
      this.videoForm.targetLanguage = "";
    },
    closeHistory() {
      this.drawerHistory = false;
    },
    openHistory() {
      this.drawerHistory = true;
    },
    makeVideo() {
      if (
        !this.videoForm.needSpeechTranslate &&
        !this.videoForm.needSubtitleTranslate &&
        !this.videoForm.needFaceTranslate
      ) {
        this.$modal.msgWarning(this.$t("videoTranslationPage.atLeastTip"));
        return;
      }
      // if (!this.videoForm.languages || this.videoForm.languages.length === 0) {
      //   this.$modal.msgError("请选择翻译语种");
      //   return;
      // }
      // if (this.videoForm.mediaList.length === 0) {
      //   this.$modal.msgError("请选择要翻译的视频");
      //   return;
      // }
      // if (
      //   this.videoForm.needSpeechTranslate &&
      //   (this.videoForm.languages.length > 1 ||
      //     this.videoForm.languages[0] !== "en")
      // ) {
      //   this.$modal.alertError("声音仅支持中文==>英文的转换，请重新选择");l
      //   return;
      // }
      // console.log(this.videoForm);
      if (
        this.videoForm.mediaList.length &&
        this.videoForm.sourceLanguage &&
        this.videoForm.targetLanguage
      ) {
        if (!this.videoForm.needSubtitleTranslate) {
          this.videoForm.bilingualSubtitle = true;
        }
        const videos = this.videoForm.mediaList.map((item) => {
          return {
            url: item.url,
            videoName: item.name,
            detextArea: "Auto",
            ocrArea: "Auto",
          };
        });
        const newForm = { ...this.videoForm };
        delete newForm.mediaList;
        this.submitLoading = true;
        videoTranslation({
          ...newForm,
          videos,
          // needSpeechTranslate: this.subtitleOrSpeech === 'needSpeechTranslate',
          // needSubtitleTranslate: this.subtitleOrSpeech === 'needSubtitleTranslate',
        })
          // videoTranslate({...this.videoForm,
          //   needSpeechTranslate: this.subtitleOrSpeech === 'needSpeechTranslate',
          //   needSubtitleTranslate: this.subtitleOrSpeech === 'needSubtitleTranslate'
          // })
          .then((res) => {
            this.videoForm.mediaList = [];
            this.$modal.msgSuccess(this.$t("videoTranslationPage.successTip"));
            this.$nextTick().then((rs) => {
              this.$refs.historyDrawer.getList();
            });
            // this.$tab.closeOpenPage({ path: "/generatedVideo" });
          })
          .finally(() => {
            this.submitLoading = false;
          });
      } else {
        this.$modal.msgWarning(this.$t("videoTranslationPage.warningTip"));
      }
    },
    getLanguage() {
      // getLanguagesFromAli().then((res) => {
      //   const newList = res.data.map((item) => {
      //     return { ...item, select: false };
      //   });
      //   this.languageList = newList.filter((item) => item.dictValue !== "zh"); // 过滤掉 'zh' 的数据
      //   // .sort((a, b) => {
      //   //   // 按照 firstWords 字段的字母顺序进行排序
      //   //   if (a.firstWords < b.firstWords) return -1;
      //   //   if (a.firstWords > b.firstWords) return 1;
      //   //   return 0;
      //   // });
      // });
    },
    showLocaMedia(items) {
      items.forEach((item) => {
        this.videoForm.mediaList.push(item);
      });
    },
    selectMaterial(items) {
      items.forEach((item) => {
        if (!item.fileType) {
          item.fileType = this.getTypeByUrl(item.url);
        }
        this.videoForm.mediaList.push({
          fileType: item.fileType,
          videoLength: item.videoLength,
          name: item.name,
          url: item.url,
          hover: false, // 添加一个hover属性来控制蒙层的显示
        });
      });
      console.log("选中的素材:", items);
      console.log("mediaList:", this.videoForm.mediaList);
      this.$forceUpdate();
    },
    removeMaterial(index) {
      this.videoForm.mediaList.splice(index, 1);
    },
    playVideo(url) {
      this.playUrl = url;
      this.dialogPlay = true;
      // 播放视频时获取视频元素
      this.$nextTick(() => {
        this.videoElement = this.$refs.videoPlayer;
        this.videoElement.play();
      });
    },
    handleClose() {
      this.dialogPlay = false;
      // 关闭弹窗时停止视频播放
      if (this.videoElement) {
        this.videoElement.pause();
        this.videoElement.currentTime = 0; // 可选：将视频播放位置重置为起始位置
      }
    },
  },
};
</script>

<style scoped lang="scss">
.videoTranslation {
  padding: 15px;
}

.RightQuota {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: right;
  font-style: normal;
  display: flex;
  align-items: center;

  ::v-deep .el-progress__text {
    display: none;
  }

  ::v-deep .el-progress-bar {
    padding-right: 0;
    display: inline-block;
    vertical-align: middle;
    width: 160px;
    margin-right: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  ::v-deep .el-progress-bar__inner {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: linear-gradient(90deg, #0089ff 0%, #6e16d1 100%);
    text-align: right;
    border-radius: 100px;
    line-height: 1;
    white-space: nowrap;
    -webkit-transition: width 0.6s ease;
    transition: width 0.6s ease;
  }
}

.contentHeight {
  width: 70%;
  height: calc(100vh - 120px);
  background: #ffffff;
  // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
  // border-radius: 14px;
  // padding: 24px;
}

.vt-left {
  height: 100%;
  width: 65%;
  overflow-y: auto;
  background: #f6f8fa;
  border-radius: 12px;
  border: 2px dashed rgba(179, 179, 179, 0.38);

  .el-form-item {
    height: 99%;
  }

  ::v-deep .el-form-item__content {
    height: 100%;
  }
}

.vt-right {
  height: 100%;
  width: 35%;
  padding: 24px 24px;
  overflow-y: auto;
}

.vt-tip {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-top: 16px;
}

.formTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .vt-title {
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 22px;
    text-align: left;
    font-style: normal;
  }

  .history {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #0156ff;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    cursor: pointer;
    display: flex;
    align-items: center;

    .icon-gongzuotai2 {
      margin-right: 4px;
    }
  }
}

.video-content {
  min-height: 500px;
  padding: 15px;
  background: #ffffff;
  border: 1px solid rgba(229, 231, 235, 1);
  border-radius: 14px;
}

.aspect-ratio-box-container {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 16px;
}

.centerUpload {
  height: 100%;
}

.aspect-ratio-box {
  // width: 200px;
  // height: 366px;
  height: calc(100vh - 150px);
  aspect-ratio: 9 / 16;
  border: 1px solid #e5e7eb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  margin-bottom: 5px;
  /* 调整间距 */
  position: relative;
}

.material-thumbnail {
  width: 100%;
  height: 100%;
  /* 根据需要调整高度 */
  //object-fit: cover;
  border-radius: 12px;
}

.material-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;

  .cricle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    cursor: pointer;

    .el-icon-caret-right {
      color: #fff;
      font-size: 35px;
    }
  }

  .deleteIcon {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 28px;
    height: 28px;
    background: #000000;
    border-radius: 8px;
    opacity: 0.5;
    cursor: pointer;
    justify-content: center;
    display: flex;
    align-items: center;

    .icon-delete {
      color: #fff;
      font-size: 16px;
    }
  }
}

.video-duration {
  position: absolute;
  right: 5px;
  bottom: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}

.el-form-item {
  margin-bottom: 5px;
}

.el-form--label-top .el-form-item__label {
  padding: 0;
}

.icon-zhushi {
  font-size: 14px;
  margin-right: 4px;
}
</style>
