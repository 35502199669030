var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "videoCarry" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isAnalysis,
              expression: "!isAnalysis",
            },
          ],
          staticClass: "faceContent",
        },
        [
          _c(
            "el-popover",
            { attrs: { placement: "top", width: "400", trigger: "hover" } },
            [
              [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("videoCarry.pleaseVideoLinkDy")),
                  },
                }),
              ],
              _c("el-input", {
                staticClass: "textareaStyle",
                attrs: {
                  slot: "reference",
                  type: "textarea",
                  rows: 2,
                  placeholder: _vm.$t("videoCarry.pleaseVideoLink"),
                  resize: "none",
                },
                slot: "reference",
                model: {
                  value: _vm.textareaUrl,
                  callback: function ($$v) {
                    _vm.textareaUrl = $$v
                  },
                  expression: "textareaUrl",
                },
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "btnBox flex-center" },
            [
              _c(
                "el-button",
                {
                  staticClass: "button-white",
                  on: { click: _vm.clearTextareaUrl },
                },
                [_vm._v(_vm._s(_vm.$t("videoCarry.clearContent")))]
              ),
              _c(
                "el-button",
                {
                  staticClass: "normal-button",
                  on: { click: _vm.pasteTextareaUrl },
                },
                [_vm._v(_vm._s(_vm.$t("videoCarry.clickPaste")))]
              ),
              _c(
                "el-button",
                {
                  staticClass: "gradient-button",
                  attrs: { loading: _vm.startAnalysisLoading },
                  on: { click: _vm.startAnalysis },
                },
                [_vm._v(_vm._s(_vm.$t("videoCarry.startParsing")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isAnalysis
        ? _c(
            "div",
            { staticClass: "flex-column-center" },
            [
              _c("div", { staticClass: "videoContent flex-center" }, [
                _vm.videoUrl
                  ? _c("div", { staticClass: "videoBox" }, [
                      _c("video", {
                        ref: "video",
                        attrs: { src: _vm.videoUrl },
                      }),
                    ])
                  : _vm._e(),
                _vm.videoUrl
                  ? _c(
                      "div",
                      {
                        staticClass: "deleteIcon",
                        on: { click: _vm.removeMaterial },
                      },
                      [_c("i", { staticClass: "iconfont icon-delete" })]
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "setContent" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "basicInfoForm",
                      attrs: {
                        model: _vm.videoForm,
                        width: "100%",
                        "label-position": "top",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.videoForm.jobProgressList,
                            callback: function ($$v) {
                              _vm.$set(_vm.videoForm, "jobProgressList", $$v)
                            },
                            expression: "videoForm.jobProgressList",
                          },
                        },
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "videoTranslation" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("videoTranslationPage.headerTitle")
                                )
                              ),
                            ]
                          ),
                          _c("el-checkbox", { attrs: { label: "mergeFace" } }, [
                            _vm._v(_vm._s(_vm.$t("faceSwap.faceChanging"))),
                          ]),
                          _c(
                            "el-checkbox",
                            { attrs: { label: "eraseSubtitle" } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("workbench.aiSubtitleRemoval"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.videoForm.jobProgressList.includes("videoTranslation")
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-top": "34px" },
                                  attrs: {
                                    label: _vm.$t(
                                      "videoTranslationPage.translationType"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      on: { change: _vm.changeType },
                                      model: {
                                        value:
                                          _vm.videoTranslation
                                            .needSpeechTranslate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.videoTranslation,
                                            "needSpeechTranslate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "videoTranslation.needSpeechTranslate",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("videoTranslationPage.sound")
                                          )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      on: { change: _vm.changeType },
                                      model: {
                                        value:
                                          _vm.videoTranslation
                                            .needSubtitleTranslate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.videoTranslation,
                                            "needSubtitleTranslate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "videoTranslation.needSubtitleTranslate",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "videoTranslationPage.subtitle"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      on: { change: _vm.changeType },
                                      model: {
                                        value:
                                          _vm.videoTranslation
                                            .needFaceTranslate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.videoTranslation,
                                            "needFaceTranslate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "videoTranslation.needFaceTranslate",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("videoTranslationPage.face")
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "videoTranslationPage.videoLanguage"
                                    ),
                                  },
                                },
                                [
                                  _vm.videoTranslation.needFaceTranslate
                                    ? _c(
                                        "el-select",
                                        {
                                          staticClass: "w-100",
                                          attrs: {
                                            placeholder: _vm.$t("tip.select"),
                                            clearable: "",
                                            filterable: "",
                                            "default-first-option": true,
                                          },
                                          model: {
                                            value:
                                              _vm.videoTranslation
                                                .sourceLanguage,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.videoTranslation,
                                                "sourceLanguage",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "videoTranslation.sourceLanguage",
                                          },
                                        },
                                        _vm._l(
                                          _vm.dict.type.source_face_language,
                                          function (dict) {
                                            return _c(
                                              "el-option",
                                              {
                                                key: dict.value,
                                                attrs: {
                                                  label: dict.label,
                                                  value: dict.value,
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(dict.label)),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm.videoTranslation.needSubtitleTranslate
                                    ? _c(
                                        "el-select",
                                        {
                                          staticClass: "w-100",
                                          attrs: {
                                            placeholder: _vm.$t("tip.select"),
                                            clearable: "",
                                            filterable: "",
                                            "default-first-option": true,
                                          },
                                          model: {
                                            value:
                                              _vm.videoTranslation
                                                .sourceLanguage,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.videoTranslation,
                                                "sourceLanguage",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "videoTranslation.sourceLanguage",
                                          },
                                        },
                                        _vm._l(
                                          _vm.dict.type.source_text_language,
                                          function (dict) {
                                            return _c(
                                              "el-option",
                                              {
                                                key: dict.value,
                                                attrs: {
                                                  label: dict.label,
                                                  value: dict.value,
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(dict.label)),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _c(
                                        "el-select",
                                        {
                                          staticClass: "w-100",
                                          attrs: {
                                            placeholder: _vm.$t("tip.select"),
                                            clearable: "",
                                            filterable: "",
                                            "default-first-option": true,
                                          },
                                          model: {
                                            value:
                                              _vm.videoTranslation
                                                .sourceLanguage,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.videoTranslation,
                                                "sourceLanguage",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "videoTranslation.sourceLanguage",
                                          },
                                        },
                                        _vm._l(
                                          _vm.dict.type.source_speech_language,
                                          function (dict) {
                                            return _c(
                                              "el-option",
                                              {
                                                key: dict.value,
                                                attrs: {
                                                  label: dict.label,
                                                  value: dict.value,
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(dict.label)),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "videoTranslationPage.translationLanguage"
                                    ),
                                  },
                                },
                                [
                                  _vm.videoTranslation.needFaceTranslate
                                    ? _c(
                                        "el-select",
                                        {
                                          staticClass: "w-100",
                                          attrs: {
                                            placeholder: _vm.$t("tip.select"),
                                            clearable: "",
                                            filterable: "",
                                            "default-first-option": true,
                                          },
                                          model: {
                                            value:
                                              _vm.videoTranslation
                                                .targetLanguage,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.videoTranslation,
                                                "targetLanguage",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "videoTranslation.targetLanguage",
                                          },
                                        },
                                        _vm._l(
                                          _vm.dict.type.target_face_language,
                                          function (dict) {
                                            return _c(
                                              "el-option",
                                              {
                                                key: dict.value,
                                                attrs: {
                                                  label: dict.label,
                                                  value: dict.value,
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(dict.label)),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm.videoTranslation.needSpeechTranslate
                                    ? _c(
                                        "el-select",
                                        {
                                          staticClass: "w-100",
                                          attrs: {
                                            placeholder: _vm.$t("tip.select"),
                                            clearable: "",
                                            filterable: "",
                                            "default-first-option": true,
                                          },
                                          model: {
                                            value:
                                              _vm.videoTranslation
                                                .targetLanguage,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.videoTranslation,
                                                "targetLanguage",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "videoTranslation.targetLanguage",
                                          },
                                        },
                                        _vm._l(
                                          _vm.dict.type.target_speech_language,
                                          function (dict) {
                                            return _c(
                                              "el-option",
                                              {
                                                key: dict.value,
                                                attrs: {
                                                  label: dict.label,
                                                  value: dict.value,
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(dict.label)),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _c(
                                        "el-select",
                                        {
                                          staticClass: "w-100",
                                          attrs: {
                                            placeholder: _vm.$t("tip.select"),
                                            clearable: "",
                                            filterable: "",
                                            "default-first-option": true,
                                          },
                                          model: {
                                            value:
                                              _vm.videoTranslation
                                                .targetLanguage,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.videoTranslation,
                                                "targetLanguage",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "videoTranslation.targetLanguage",
                                          },
                                        },
                                        _vm._l(
                                          _vm.dict.type.target_text_language,
                                          function (dict) {
                                            return _c(
                                              "el-option",
                                              {
                                                key: dict.value,
                                                attrs: {
                                                  label: dict.label,
                                                  value: dict.value,
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(dict.label)),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                ],
                                1
                              ),
                              _vm.videoTranslation.needSubtitleTranslate
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "videoTranslationPage.keepSubtitle"
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: true },
                                          model: {
                                            value:
                                              _vm.videoTranslation
                                                .bilingualSubtitle,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.videoTranslation,
                                                "bilingualSubtitle",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "videoTranslation.bilingualSubtitle",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "videoTranslationPage.keep"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: false },
                                          model: {
                                            value:
                                              _vm.videoTranslation
                                                .bilingualSubtitle,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.videoTranslation,
                                                "bilingualSubtitle",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "videoTranslation.bilingualSubtitle",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "videoTranslationPage.discard"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.videoForm.jobProgressList.includes("mergeFace")
                        ? _c("div", [
                            _c("div", { staticClass: "vt-tip" }, [
                              _c("i", { staticClass: "iconfont icon-zhushi" }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("faceSwap.tip")) +
                                  ", " +
                                  _vm._s(_vm.$t("file.invalidResolution")) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "addFaceBox" }, [
                              _c("div", { staticClass: "add-title" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("faceSwap.uploadFaceShape")) +
                                    ":"
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "uploadFace" },
                                [
                                  !_vm.facePicUrl
                                    ? _c("aiUploadPic", {
                                        on: {
                                          picSelectMaterial: function ($event) {
                                            return _vm.chooseMaterial(4)
                                          },
                                          picSelectSystem: _vm.chooseSystemPic,
                                          showLocaPic: _vm.showLocaPic4,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.facePicUrl
                                    ? _c("div", { staticClass: "facePicBox" }, [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.facePicUrl,
                                            alt: "",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "deleteIcon",
                                            on: { click: _vm.removeFacePic },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "iconfont icon-delete",
                                            }),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "gradient-button",
                  on: { click: _vm.goToPublish },
                },
                [_vm._v("一键发布")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("historyDrawer", {
        ref: "historyDrawer",
        attrs: { type: "transport", drawerHistory: _vm.drawerHistory },
        on: { closeHistory: _vm.closeHistory },
      }),
      _c("material-drawer", {
        ref: "materialDrawer",
        attrs: { visible: _vm.drawer },
        on: {
          "update:visible": function ($event) {
            _vm.drawer = $event
          },
          selectionConfirmed: _vm.selectionConfirmed,
        },
      }),
      _vm.dialogPlay
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.dialogPlay, width: "50%" },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogPlay = $event
                },
              },
            },
            [
              _c("video", {
                ref: "videoPlayer",
                staticClass: "video-player",
                attrs: { controls: "", src: _vm.playUrl },
              }),
            ]
          )
        : _vm._e(),
      _vm.publishVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "publicDialog",
              attrs: {
                width: "50%",
                visible: _vm.publishVisible,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                "before-close": _vm.handleBeforeClose,
                "show-close": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.publishVisible = $event
                },
              },
            },
            [
              _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                _c("div", { staticClass: "headerFlex" }, [
                  _c("div", { staticClass: "headerTitle" }, [
                    _vm._v(_vm._s(_vm.$t("videoCarry.editingCopy"))),
                  ]),
                  _c("i", {
                    staticClass: "el-icon-close",
                    on: { click: _vm.handleBeforeClose },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "dialogContent" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "ruleForm",
                        staticClass: "demo-ruleForm",
                        attrs: { model: _vm.ruleForm, rules: _vm.rules },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "postContent" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "videoCarry.PleaseEnterText"
                                ),
                                type: "textarea",
                                rows: 4,
                                resize: "none",
                              },
                              model: {
                                value: _vm.ruleForm.postContent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "postContent", $$v)
                                },
                                expression: "ruleForm.postContent",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "account" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  multiple: "",
                                  placeholder: _vm.$t(
                                    "videoCarry.selectAccount"
                                  ),
                                },
                                model: {
                                  value: _vm.ruleForm.account,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "account", $$v)
                                  },
                                  expression: "ruleForm.account",
                                },
                              },
                              _vm._l(_vm.accountOptions, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.username,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "cancelBtn",
                            on: { click: _vm.handleBeforeClose },
                          },
                          [_vm._v(_vm._s(_vm.$t("cancel")))]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "submitBtn",
                            attrs: { loading: _vm.buttonLoading },
                            on: {
                              click: function ($event) {
                                return _vm.submitForm("ruleForm")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("videoManager.publish")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }