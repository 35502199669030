import { render, staticRenderFns } from "./boxSelectCanvas.vue?vue&type=template&id=09a05203&scoped=true"
import script from "./boxSelectCanvas.vue?vue&type=script&lang=js"
export * from "./boxSelectCanvas.vue?vue&type=script&lang=js"
import style0 from "./boxSelectCanvas.vue?vue&type=style&index=0&id=09a05203&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09a05203",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/buildhome/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('09a05203')) {
      api.createRecord('09a05203', component.options)
    } else {
      api.reload('09a05203', component.options)
    }
    module.hot.accept("./boxSelectCanvas.vue?vue&type=template&id=09a05203&scoped=true", function () {
      api.rerender('09a05203', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ai/components/boxSelectCanvas.vue"
export default component.exports