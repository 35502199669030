<!-- 去字幕 -->
<template>
  <div class="unsubtitle">
    <div class="whiteContent">
      <div class="faceContent flex-column">
        <div class="leftUpload flex-center">
          <ai-uploadFile
            v-if="!selectVideoList.length"
            :initNum="6"
            :fileType="fileType"
            @selectMaterial="selectMaterial"
            @showLocaMedia="showLocaMedia"
          />
          <boxSelectCanvas
            v-if="videoUrl && subtitleWay === '1'"
            ref="boxSelectCanvas"
            :videoUrl="videoUrl"
          />
          <div
            class="videoBox"
            v-if="videoUrl && subtitleWay === '2'"
          >
            <video
              ref="video"
              :src="videoUrl"
            ></video>
          </div>
          <div
            v-if="videoUrl"
            class="deleteIcon"
            @click="removeMaterial"
          >
            <i class="iconfont icon-delete"></i>
          </div>
        </div>
        <div class="rightForm flex-column">
          <div class="formTitle">
            <div class="r-title">{{ $t('unsubtitle.setting') }}</div>
            <!-- <div
              class="history"
              @click="openHistory"
            ><i class="iconfont icon-gongzuotai2"></i>{{ $t('videoTranslationPage.history') }}</div> -->
          </div>
          <div class="addFaceBox">
            <div class="add-title">{{$t('unsubtitle.removeSubtitles')}}:</div>
            <el-radio-group v-model="subtitleWay">
              <el-radio
                label="1"
                border
              >{{$t('unsubtitle.selectArea')}}</el-radio>
              <el-radio
                label="2"
                border
                class="marginRadio"
              >{{$t('unsubtitle.fullScreen')}}</el-radio>
            </el-radio-group>
          </div>
          <!-- <div class="addFaceBox" v-if="subtitleWay === '1'">
              <div class="title">框选操作:</div>
              <div class="flex-row">
                <el-button type="primary" plain @click="addSubtitleRect"><i class="iconfont icon-tianjia"></i>添加字幕框</el-button>
                <el-button class="marginLeftBtn" type="primary" plain @click="clearAllRects"><i class="iconfont icon-delete"></i>清空字幕框</el-button>
              </div>
            </div> -->
          <div class="selectSaveType">
            <div class="loc-title">{{$t('faceSwap.storageLocation')}}:</div>
            <el-radio-group v-model="saveTo">
              <el-radio label="material">{{ $t('videoTranslationPage.material') }}</el-radio>
              <el-radio label="product">{{ $t('videoTranslationPage.work') }}</el-radio>
            </el-radio-group>
          </div>

          <el-button
            :loading="submitLoading"
            class="gradient-button self-center"
            @click="submitUnsubtitle"
            style="margin: 25px 0;width:200px"
          >{{ $t('unsubtitle.clickSubtitleRemoval') }}</el-button>
        </div>
      </div>
    </div>
    <el-dialog
      v-if="dialogPlay"
      :visible.sync="dialogPlay"
      width="50%"
    >
      <video
        controls
        :src="playUrl"
        class="video-player"
        ref="videoPlayer"
      ></video>
    </el-dialog>
    <!-- v-if="drawerHistory" -->
    <historyDrawer
      ref="historyDrawer"
      type="eraseSubtitle"
      :drawerHistory="drawerHistory"
      @closeHistory="closeHistory"
    />
  </div>
</template>

<script>
import boxSelectCanvas from "./components/boxSelectCanvas.vue";
import uploadMaterialAndLoca from "./components/uploadMaterialAndLoca.vue";
import aiUploadFile from "./components/aiUploadFile.vue";
import historyDrawer from "./components/historyDrawer.vue";
import { eraseSubtitle } from "../../api/ai/aiFacewarp";
export default {
  name: "Unsubtitle",
  components: {
    uploadMaterialAndLoca,
    aiUploadFile,
    historyDrawer,
    boxSelectCanvas,
  },
  data() {
    return {
      quota: {
        usePublishCount: 0,
        publishCount: 100,
      }, //配额
      drawer: false, //素材选择的抽屉
      saveTo: "material",
      videoUrl: "",
      selectVideoList: [],
      playUrl: "",
      dialogPlay: false,
      fileType: ["mp4", "avi", "mkv", "mov"],
      submitLoading: false,
      drawerHistory: false,
      subtitleWay: "1",
    };
  },
  methods: {
    removeMaterial() {
      this.videoUrl = "";
      this.selectVideoList = [];
    },
    // clearAllRects() {
    //   if (this.$refs.boxSelectCanvas) {
    //     this.$refs.boxSelectCanvas.clearAllRects()
    //   }
    // },
    // addSubtitleRect() {
    //   if (this.$refs.boxSelectCanvas) {
    //     this.$refs.boxSelectCanvas.addInitialRect()
    //   }
    // },
    filterSeletVideoList() {
      let coordList = [];
      if (this.$refs.boxSelectCanvas) {
        coordList = this.$refs.boxSelectCanvas.exportRegions() || [];
      }
      console.log(coordList);
      return this.selectVideoList.map((item) => {
        return { url: item.url, videoName: item.name, ...coordList[0] };
      });
    },
    closeHistory() {
      this.drawerHistory = false;
    },
    openHistory() {
      this.drawerHistory = true;
    },
    async submitUnsubtitle() {
      if (this.filterSeletVideoList().length && this.saveTo) {
        try {
          this.submitLoading = true;
          await eraseSubtitle({
            videos: this.filterSeletVideoList(),
            saveTo: this.saveTo,
          });
          this.videoUrl = "";
          this.selectVideoList = [];
          this.submitLoading = false;
          this.$modal.msgSuccess(this.$t("videoTranslationPage.successTip"));
          this.$nextTick().then((rs) => {
            this.$refs.historyDrawer.getList();
          });
        } catch (e) {
          this.submitLoading = false;
          console.log(e);
        }
      } else {
        this.$modal.msgWarning(this.$t("faceSwap.warningTip"));
      }
    },
    showLocaMedia(items) {
      this.videoUrl = items[0].url;
      items.forEach((item) => {
        this.selectVideoList.push(item);
      });
    },
    selectMaterial(items) {
      this.videoUrl = items[0].url;
      items.forEach((item) => {
        this.selectVideoList.push(item);
      });
    },
    playVideo(url) {
      this.playUrl = url;
      this.dialogPlay = true;
      // 播放视频时获取视频元素
      this.$nextTick(() => {
        this.videoElement = this.$refs.videoPlayer;
        this.videoElement.play();
      });
    },
    chooseMaterial(num) {
      this.drawer = true;
      let type;
      if (num === 2) {
        type = 2;
      } else if (num === 3 || num === 4 || num === 5) {
        type = 3;
      }
      this.$nextTick().then((rs) => {
        this.$refs.materialLibrary.init(type);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.unsubtitle {
  padding: 15px;
}
.whiteContent {
  width: 100%;
  // height: calc(100vh - 120px);
  background: #ffffff;
  overflow-y: auto;
}
.faceContent {
  width: 70%;
  // min-width: 1000px;
  margin: auto;
}
.leftUpload {
  width: 100%;
  height: 520px;
  padding: 16px;
  background: #f6f8fa;
  border-radius: 12px;
  border: 2px dashed rgba(179, 179, 179, 0.38);
  position: relative;
  .videoBox {
    position: relative;
    width: 275.3px;
    height: 488px;
    video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }
  }
  .deleteIcon {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 28px;
    height: 28px;
    background: #000000;
    border-radius: 8px;
    opacity: 0.5;
    cursor: pointer;
    justify-content: center;
    display: flex;
    align-items: center;
    .icon-delete {
      color: #fff;
      font-size: 16px;
    }
  }
}
.rightForm {
  width: 100%;
  margin-top: 23px;
  padding: 0 24px;
  position: relative;
  overflow-y: auto;
  .formTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .r-title {
      font-family: PingFang-SC, PingFang-SC;
      font-weight: bold;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 22px;
      text-align: left;
      font-style: normal;
    }
    .history {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #0156ff;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      cursor: pointer;
      display: flex;
      align-items: center;
      .icon-gongzuotai2 {
        margin-right: 4px;
      }
    }
  }
  .vt-tip {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
    line-height: 22px;
    text-align: left;
    font-style: normal;
    margin-top: 16px;
    display: flex;
    .icon-zhushi {
      font-size: 14px;
      margin-right: 4px;
    }
  }
  .addFaceBox {
    margin-top: 34px;
    .add-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 20px;
      text-align: left;
      font-style: normal;
      margin-bottom: 8px;
    }
    .marginRadio {
      margin-left: -6px;
    }
    .el-radio--medium.is-bordered {
      width: 140px;
      height: 40px;
      // padding: 10px 20px 0 10px;
      border-radius: 12px;
      height: 36px;
    }
    .marginLeftBtn {
      margin-left: 24px;
    }
    .el-button--primary.is-plain:active {
      background: #f2f6ff;
      border-color: #1682e6;
      color: #1890ff;
      outline: none;
    }
    .el-button--primary.is-plain:hover,
    .el-button--primary.is-plain:focus {
      background: #f2f6ff;
      border-color: #1890ff;
      color: #1890ff;
    }
    .el-button {
      width: 140px;
      height: 32px;
      background: #f2f6ff;
      border-radius: 12px;
      display: flex;
      align-items: center;
      .iconfont {
        font-size: 16px;
        margin-right: 4px;
      }
    }
  }
  .selectSaveType {
    margin-top: 34px;
    .loc-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 20px;
      text-align: left;
      font-style: normal;
      margin-bottom: 18px;
    }
  }
}
</style>
