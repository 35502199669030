<template>
  <div class="video-container">
    <video
      ref="video"
      :src="videoUrl"
      @loadedmetadata="onVideoLoad"
    ></video>
    <canvas
      ref="canvas"
      class="overlay-canvas"
    ></canvas>
  </div>
</template>

<script>
import { fabric } from "fabric";
export default {
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      canvas: null,
      videoWidth: 0,
      videoHeight: 0,
      regions: [], // 存储字幕框
    };
  },
  methods: {
    onVideoLoad() {
      const video = this.$refs.video;
      this.videoWidth = video.videoWidth;
      this.videoHeight = video.videoHeight;
      this.fitVideoAndCanvas();
    },
    fitVideoAndCanvas() {
      const video = this.$refs.video;
      const canvasElement = this.$refs.canvas;
      const container = this.$el;

      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;
      const videoAspectRatio = this.videoWidth / this.videoHeight;
      const containerAspectRatio = containerWidth / containerHeight;
      if (videoAspectRatio > containerAspectRatio) {
        const scaledHeight = containerWidth / videoAspectRatio;
        video.style.width = "100%";
        video.style.height = "auto";
        video.style.marginTop = `${(containerHeight - scaledHeight) / 2}px`;

        canvasElement.width = containerWidth;
        canvasElement.height = scaledHeight;
        canvasElement.style.marginTop = `${
          (containerHeight - scaledHeight) / 2
        }px`;
      } else {
        const scaledWidth = containerHeight * videoAspectRatio;
        video.style.width = "auto";
        video.style.height = "100%";
        video.style.marginLeft = `${(containerWidth - scaledWidth) / 2}px`;

        canvasElement.width = scaledWidth;
        canvasElement.height = containerHeight;
        canvasElement.style.marginLeft = `${
          (containerWidth - scaledWidth) / 2
        }px`;
      }

      this.canvas = new fabric.Canvas(canvasElement, {
        selection: true,
      });
      this.addInitialRect();
    },
    addInitialRect() {
      const rect = this.createRect(50, 50, 150, 75);
      this.regions.push(rect);
    },
    createRect(x, y, width, height) {
      const rect = new fabric.Rect({
        left: x,
        top: y,
        width: width,
        height: height,
        fill: "rgba(255,255,255,0.1)",
        stroke: "#9C00FF",
        strokeWidth: 1.5,
        selectable: true, // 对象可以选中
        hasControls: true, // 启用缩放和旋转控制点
        lockScalingFlip: true, // 防止缩放时翻转
        lockUniScaling: true, // 允许独立改变宽和高
      });

      this.canvas.add(rect);
      this.addDeleteControl(rect);
      return rect;
    },
    // 自定义删除按钮
    addDeleteControl(rect) {
      //   fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      //     x: 0.5,
      //     y: -0.5,
      //     offsetY: 15,
      //     offsetX: -15,
      //     cursorStyle: "pointer",
      //     mouseUpHandler: (eventData, transform) => {
      //       const target = transform.target;
      //       this.canvas.remove(target);
      //       this.regions = this.regions.filter((region) => region !== target);
      //       this.canvas.requestRenderAll();
      //     },
      //     render: function (ctx, left, top) {
      //       const size = this.sizeX || 24;
      //       const radius = 5;
      //       const squareSize = size; // 正方形边长
      //       // 绘制圆角矩形
      //       ctx.save();
      //       ctx.fillStyle = "rgba(0,0,0,0.5)";
      //       ctx.beginPath();
      //       ctx.moveTo(left - squareSize / 2 + radius, top - squareSize / 2);
      //       ctx.lineTo(left + squareSize / 2 - radius, top - squareSize / 2);
      //       ctx.arcTo(
      //         left + squareSize / 2,
      //         top - squareSize / 2,
      //         left + squareSize / 2,
      //         top + squareSize / 2,
      //         radius
      //       );
      //       ctx.lineTo(left + squareSize / 2, top + squareSize / 2 - radius);
      //       ctx.arcTo(
      //         left + squareSize / 2,
      //         top + squareSize / 2,
      //         left - squareSize / 2,
      //         top + squareSize / 2,
      //         radius
      //       );
      //       ctx.lineTo(left - squareSize / 2 + radius, top + squareSize / 2);
      //       ctx.arcTo(
      //         left - squareSize / 2,
      //         top + squareSize / 2,
      //         left - squareSize / 2,
      //         top - squareSize / 2,
      //         radius
      //       );
      //       ctx.lineTo(left - squareSize / 2, top - squareSize / 2 + radius);
      //       ctx.arcTo(
      //         left - squareSize / 2,
      //         top - squareSize / 2,
      //         left + squareSize / 2,
      //         top - squareSize / 2,
      //         radius
      //       );
      //       ctx.closePath();
      //       ctx.fill();
      //       // 绘制 X 文本
      //       ctx.fillStyle = "white";
      //       ctx.font = `${size - 10}px Arial`;
      //       ctx.textAlign = "center";
      //       ctx.textBaseline = "middle";
      //       ctx.fillText("X", left, top);
      //       ctx.restore();
      //     },
      //   });
      rect.setControlsVisibility({
        mt: true,
        mb: true,
        ml: true,
        mr: true,
        bl: true,
        br: true,
        tl: true,
        tr: true,
        mtr: false,
      });
    },
    exportRegions() {
      const canvasBounds = this.$refs.canvas.getBoundingClientRect();
      const normalizedRegions = this.regions.map((rect) => {
        const updatedWidth = rect.width * rect.scaleX;
        const updatedHeight = rect.height * rect.scaleY;
        const clamp = (val) => Math.min(1, Math.max(0, val));
        return {
          bx: clamp(rect.left / canvasBounds.width),
          by: clamp(rect.top / canvasBounds.height),
          bw: clamp(updatedWidth / canvasBounds.width),
          bh: clamp(updatedHeight / canvasBounds.height),
        };
      });
      console.log("字幕区域占比：", normalizedRegions);
      return normalizedRegions;
    },
    // clearAllRects() {
    //   this.canvas.getObjects().forEach((obj) => {
    //     if (obj instanceof fabric.Rect) {
    //       this.canvas.remove(obj);
    //     }
    //   });
    //   this.regions = [];
    //   this.canvas.requestRenderAll();
    // },
    // exportRegions() {
    //   const canvasBounds = this.$refs.canvas.getBoundingClientRect();
    //   const normalizedRegions = this.regions.map((rect) => ({
    //     xmin: rect.left / canvasBounds.width,
    //     ymin: rect.top / canvasBounds.height,
    //     width: rect.width / canvasBounds.width,
    //     height: rect.height / canvasBounds.height,
    //   }));
    //   console.log("归一化字幕区域：", normalizedRegions);
    // },
  },
};
</script>

  <style lang="scss" scoped>
.video-container {
  position: relative;
  width: 968px;
  height: 488px;
  // background: #000;
  // margin: auto;
}
video,
canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
</style>
