var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "uploadbox",
    },
    [
      _c(
        "div",
        { staticClass: "material", on: { click: _vm.selectMaterial } },
        [
          _c("i", { staticClass: "iconfont icon-tianjia" }),
          _c(
            "el-button",
            { staticClass: "uploadBtn", attrs: { type: "primary", round: "" } },
            [_vm._v(_vm._s(_vm.$t("videoTranslationPage.selectFromLibrary")))]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "line" }),
      _c(
        "div",
        { staticClass: "material" },
        [
          _c("i", { staticClass: "iconfont icon-shangchuan" }),
          _c(
            "el-upload",
            {
              attrs: {
                action: "",
                "before-upload": _vm.handleBeforeUpload,
                limit: 1,
              },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "uploadBtn",
                  attrs: { type: "primary", round: "" },
                },
                [_vm._v(_vm._s(_vm.$t("file.localUpload")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("material-drawer", {
        ref: "materialDrawer",
        attrs: { visible: _vm.drawerMaterial },
        on: {
          "update:visible": function ($event) {
            _vm.drawerMaterial = $event
          },
          selectionConfirmed: _vm.selectionMaterial,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }