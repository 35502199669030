<!-- AI换脸 -->
<template>
  <div class="faceSwap">
    <div class="flex-center w-100">
      <div class="flex-row faceContent">
        <div class="leftUpload">
          <div class="centerUpload flex-center">
            <div
              v-for="(material, index) in selectVideoList"
              :key="index"
              class="aspect-ratio-box flex-column"
            >
              <div class="material-container">
                <video
                  :src="material.url"
                  class="material-thumbnail"
                ></video>
                <div
                  class="cricle"
                  @click="playVideo(material.url)"
                >
                  <i class="el-icon-caret-right"></i>
                </div>
                <div
                  class="deleteIcon"
                  @click="removeMaterial(index)"
                >
                  <i class="iconfont icon-delete"></i>
                </div>
              </div>
              <div
                class="video-duration"
                v-if="material.fileType === 'video' && material.videoLength"
              >{{ secondsFormatTime(material.videoLength) }}</div>
            </div>
            <!-- <uploadMaterialAndLoca
              v-if="selectVideoList.length"
              :fileType="fileType"
              @selectMaterial="selectMaterial"
              @showLocaMedia="showLocaMedia"
            /> -->
            <ai-uploadFile
              v-if="!selectVideoList.length"
              :fileType="fileType"
              :initNum="6"
              @selectMaterial="selectMaterial"
              @showLocaMedia="showLocaMedia"
            />
            <!-- <ai-uploadFile
            v-if="!selectVideoList.length"
            :fileType="fileType"
            @selectMaterial="selectMaterial"
            @showLocaMedia="showLocaMedia"
          /> -->
          </div>
        </div>
        <div class="rightForm flex-column">
          <div class="f-grow">
            <div class="formTitle">
              <div class="fs-title">{{ $t('videoTranslationPage.videoSettings') }}</div>
              <!-- <div
                class="history"
                @click="openHistory"
              ><i class="iconfont icon-gongzuotai2"></i>{{ $t('videoTranslationPage.history') }}</div> -->
            </div>
            <div class="vt-tip">
              <i class="iconfont icon-zhushi"></i>
              {{ $t('faceSwap.tip') }},&nbsp;{{ $t('file.invalidResolution') }}
            </div>
            <div class="addFaceBox">
              <div class="add-title">{{ $t('faceSwap.uploadFaceShape') }}:</div>
              <div class="uploadFace">
                <aiUploadPic
                  v-if="!facePicUrl"
                  @picSelectMaterial="chooseMaterial(4)"
                  @picSelectSystem="chooseSystemPic"
                  @showLocaPic="showLocaPic4"
                />
                <div
                  class="facePicBox"
                  v-if="facePicUrl"
                >
                  <img
                    :src="facePicUrl"
                    alt=""
                  />
                  <div
                    class="deleteIcon"
                    @click="removeFacePic"
                  >
                    <i class="iconfont icon-delete"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="selectSaveType">
              <div class="loc-title">{{$t('faceSwap.storageLocation')}}:</div>
              <el-radio-group v-model="saveTo">
                <el-radio label="material">{{ $t('videoTranslationPage.material') }}</el-radio>
                <el-radio label="product">{{ $t('videoTranslationPage.work') }}</el-radio>
              </el-radio-group>
            </div>
          </div>

          <el-button
            :loading="submitLoading"
            class="gradient-button self-center"
            @click="submitFace"
            style="margin: 25px 0;width:200px"
          >{{ $t('faceSwap.clickAIface') }}</el-button>
        </div>
      </div>
    </div>
    <material-drawer
      ref="materialDrawer"
      :visible.sync="drawer"
      @selectionConfirmed="selectionConfirmed"
    />
    <el-dialog
      v-if="dialogPlay"
      :visible.sync="dialogPlay"
      width="50%"
    >
      <video
        controls
        :src="playUrl"
        class="video-player"
        ref="videoPlayer"
      ></video>
    </el-dialog>
    <!-- v-if="drawerHistory" -->
    <historyDrawer
      ref="historyDrawer"
      type="mergeFace"
      :drawerHistory="drawerHistory"
      @closeHistory="closeHistory"
    />
  </div>
</template>

<script>
import uploadMaterialAndLoca from "./components/uploadMaterialAndLoca.vue";
import aiUploadFile from "./components/aiUploadFile.vue";
import aiUploadPic from "@/components/aiUploadPic/index.vue";
import MaterialDrawer from "@/components/MaterialDrawer/index.vue";
import historyDrawer from "./components/historyDrawer.vue";
import { setMergeFace } from "../../api/ai/aiFacewarp";
export default {
  name: "FaceSwap",
  components: {
    uploadMaterialAndLoca,
    aiUploadFile,
    aiUploadPic,
    MaterialDrawer,
    historyDrawer,
  },
  data() {
    return {
      quota: {
        usePublishCount: 0,
        publishCount: 100,
      }, //配额
      drawer: false, //素材选择的抽屉
      saveTo: "material",
      selectVideoList: [],
      playUrl: "",
      dialogPlay: false,
      fileType: ["mp4", "avi", "mkv", "mov"],
      submitLoading: false,
      facePicUrl: "",
      drawerHistory: false,
    };
  },
  methods: {
    filterSeletVideoList() {
      return this.selectVideoList.map((item) => {
        return { url: item.url, videoName: item.name };
      });
    },
    closeHistory() {
      this.drawerHistory = false;
    },
    openHistory() {
      this.drawerHistory = true;
    },
    removeFacePic() {
      this.facePicUrl = "";
    },
    showLocaPic4(items) {
      this.facePicUrl = items[0].url;
    },
    async submitFace() {
      if (
        this.filterSeletVideoList().length &&
        this.facePicUrl &&
        this.saveTo
      ) {
        try {
          this.submitLoading = true;
          await setMergeFace({
            videos: this.filterSeletVideoList(),
            referenceUrl: this.facePicUrl,
            // videoName: this.selectVideoList[0].name,
            saveTo: this.saveTo,
          });
          this.facePicUrl = "";
          this.selectVideoList = [];
          this.submitLoading = false;
          this.$modal.msgSuccess(this.$t("videoTranslationPage.successTip"));
          this.$nextTick().then((rs) => {
            this.$refs.historyDrawer.getList();
          });
          // console.log(this.filterSeletVideoList(), this.facePicUrl, this.saveTo, '表单');
        } catch (e) {
          this.submitLoading = false;
          console.log(e);
        }
      } else {
        this.$modal.msgWarning(this.$t("faceSwap.warningTip"));
      }
    },
    removeMaterial(index) {
      this.selectVideoList.splice(index, 1);
    },
    showLocaMedia(items) {
      items.forEach((item) => {
        this.selectVideoList.push(item);
      });
    },
    selectMaterial(items) {
      // if(items.length === 1) {
      //   this.selectVideoList.push(items[0]);
      // } else {
      //   this.$message({ message: '请选择单个素材', type: 'warning' })
      // }
      items.forEach((item) => {
        this.selectVideoList.push(item);
      });
    },
    playVideo(url) {
      this.playUrl = url;
      this.dialogPlay = true;
      // 播放视频时获取视频元素
      this.$nextTick(() => {
        this.videoElement = this.$refs.videoPlayer;
        this.videoElement.play();
      });
    },
    chooseMaterial(num) {
      this.drawer = true;
      let type;
      if (num === 2) {
        type = 2;
      } else if (num === 3 || num === 4 || num === 5) {
        type = 3;
      }
      this.$nextTick().then((rs) => {
        this.$refs.materialDrawer.$refs.materialLibrary.init(type);
      });
    },
    selectionConfirmed(items) {
      if (items.length == 1) {
        if (this.drawerMaterialType == 3) {
          this.settings.logoTrackClipsBo.mediaUrl = items[0].url;
        } else if (this.drawerMaterialType == 4) {
          this.settings.coverUrl = items[0].url;
        } else if (this.drawerMaterialType == 5) {
          this.settings.backCoverUrl = items[0].url;
        }
      }
      this.facePicUrl = items[0].url;
      // 这里可以执行进一步的处理，例如保存到数据中等
      this.drawer = false;
      // this.handleItemUpdated()
    },
    chooseSystemPic(url) {
      this.facePicUrl = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.faceSwap {
  padding: 15px;
}
.faceContent {
  width: 70%;
  height: calc(100vh - 120px);
  background: #ffffff;
}
.leftUpload {
  width: 65%;
  height: 100%;
  overflow-y: auto;
  background: #f6f8fa;
  border-radius: 12px;
  border: 2px dashed rgba(179, 179, 179, 0.38);
}
.rightForm {
  width: 35%;
  height: 100%;
  padding: 24px;
  // position: relative;
  overflow-y: auto;
}
.vt-tip {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-top: 16px;
  display: flex;
}
.icon-zhushi {
  font-size: 14px;
  margin-right: 4px;
}
.centerUpload {
  height: 100%;
}
.aspect-ratio-box {
  // height: 366px;
  // width: 200px;
  height: calc(100vh - 150px);
  aspect-ratio: 9 / 16;
  border: 1px solid #e5e7eb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  margin-bottom: 5px; /* 调整间距 */
  position: relative;
  .material-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    .material-thumbnail {
      width: 100%;
      height: 100%; /* 根据需要调整高度 */
      object-fit: cover;
      border-radius: 12px;
    }
    .cricle {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 54px;
      height: 54px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.5);
      cursor: pointer;
      .el-icon-caret-right {
        color: #fff;
        font-size: 35px;
      }
    }
    .deleteIcon {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 28px;
      height: 28px;
      background: #000000;
      border-radius: 8px;
      opacity: 0.5;
      cursor: pointer;
      justify-content: center;
      display: flex;
      align-items: center;
      .icon-delete {
        color: #fff;
        font-size: 16px;
      }
    }
  }
}
.video-duration {
  position: absolute;
  right: 5px;
  bottom: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}

.formTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .fs-title {
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 22px;
    text-align: left;
    font-style: normal;
  }
  .history {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #0156ff;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
    .icon-gongzuotai2 {
      margin-right: 4px;
    }
  }
}

.addFaceBox {
  margin-top: 34px;
  .add-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin-bottom: 8px;
  }
  .uploadFace {
    display: flex;
  }
  .facePicBox {
    width: 180px;
    height: 293px;
    border-radius: 12px;
    overflow: hidden;
    margin-left: 24px;
    position: relative;
    img {
      width: 180px;
      height: 293px;
      object-fit: cover;
    }
    .deleteIcon {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 28px;
      height: 28px;
      background: #000000;
      border-radius: 8px;
      opacity: 0.5;
      cursor: pointer;
      justify-content: center;
      display: flex;
      align-items: center;
      .icon-delete {
        color: #fff;
        font-size: 16px;
      }
    }
  }
}
.selectSaveType {
  margin-top: 34px;
  .loc-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin-bottom: 18px;
  }
}
</style>
