var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drawer-history" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading && !_vm.isRefresh,
              expression: "loading && !isRefresh",
            },
          ],
          staticClass: "conyent-history",
        },
        [
          _c(
            "div",
            { staticClass: "historyList" },
            _vm._l(_vm.taskList, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "historyItem",
                  on: {
                    click: function ($event) {
                      return _vm.viewItem(item)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "imageCover",
                    attrs: {
                      mode: "aspectFill",
                      src: item.finishedCover || item.sourceCover,
                      alt: "",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "historyItem-state", class: item.jobStatus },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: item.errorMsg,
                            placement: "top",
                          },
                        },
                        [
                          item.jobStatus === "Failed"
                            ? _c("i", { staticClass: "iconfont icon-zhushi" })
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" " + _vm._s(_vm.getStatus(item.jobStatus)) + " "),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "createTime" }, [
                    _vm._v(" " + _vm._s(item.createTime.split(" ")[0]) + " "),
                  ]),
                ]
              )
            }),
            0
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.queryParams.pageNum,
              limit: _vm.queryParams.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryParams, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryParams, "pageSize", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm.dialogPlay
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.dialogPlay, width: "50%" },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogPlay = $event
                },
              },
            },
            [
              _c("video", {
                ref: "videoPlayer",
                staticClass: "video-player",
                attrs: { controls: "", src: _vm.playUrl },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }