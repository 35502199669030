var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "videoTranslation" },
    [
      _c(
        "el-form",
        {
          ref: "basicInfoForm",
          attrs: {
            model: _vm.videoForm,
            width: "100%",
            "label-position": "top",
          },
        },
        [
          _c("div", { staticClass: "flex-center w-100" }, [
            _c("div", { staticClass: "flex-row contentHeight" }, [
              _c(
                "div",
                { staticClass: "vt-left" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "formItemHeight",
                      attrs: { prop: "mediaList" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "centerUpload flex-center" },
                        [
                          _vm._l(
                            _vm.videoForm.mediaList,
                            function (material, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "aspect-ratio-box flex-column",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "material-container" },
                                    [
                                      _c("video", {
                                        staticClass: "material-thumbnail",
                                        attrs: { src: material.url },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "cricle",
                                          on: {
                                            click: function ($event) {
                                              return _vm.playVideo(material.url)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-caret-right",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "deleteIcon",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeMaterial(index)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "iconfont icon-delete",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  material.fileType === "video" &&
                                  material.videoLength
                                    ? _c(
                                        "div",
                                        { staticClass: "video-duration" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.secondsFormatTime(
                                                material.videoLength
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }
                          ),
                          _vm.videoForm.mediaList.length == 0
                            ? _c("ai-uploadFile", {
                                attrs: { fileType: _vm.fileType, initNum: 6 },
                                on: {
                                  selectMaterial: _vm.selectMaterial,
                                  showLocaMedia: _vm.showLocaMedia,
                                },
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vt-right flex-column h-100" },
                [
                  _c("div", { staticClass: "formTitle" }, [
                    _c("div", { staticClass: "vt-title" }, [
                      _vm._v(
                        _vm._s(_vm.$t("videoTranslationPage.videoSettings"))
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "vt-tip" }, [
                    _c("i", { staticClass: "iconfont icon-zhushi" }),
                    _vm._v(
                      " " + _vm._s(_vm.$t("videoTranslationPage.tip")) + " "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "f-grow" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-top": "34px" },
                          attrs: {
                            label: _vm.$t(
                              "videoTranslationPage.translationType"
                            ),
                          },
                        },
                        [
                          _c(
                            "el-checkbox",
                            {
                              on: { change: _vm.changeType },
                              model: {
                                value: _vm.videoForm.needSpeechTranslate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.videoForm,
                                    "needSpeechTranslate",
                                    $$v
                                  )
                                },
                                expression: "videoForm.needSpeechTranslate",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("videoTranslationPage.sound"))
                              ),
                            ]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              on: { change: _vm.changeType },
                              model: {
                                value: _vm.videoForm.needSubtitleTranslate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.videoForm,
                                    "needSubtitleTranslate",
                                    $$v
                                  )
                                },
                                expression: "videoForm.needSubtitleTranslate",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("videoTranslationPage.subtitle"))
                              ),
                            ]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              on: { change: _vm.changeType },
                              model: {
                                value: _vm.videoForm.needFaceTranslate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.videoForm,
                                    "needFaceTranslate",
                                    $$v
                                  )
                                },
                                expression: "videoForm.needFaceTranslate",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("videoTranslationPage.face"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("videoTranslationPage.videoLanguage"),
                          },
                        },
                        [
                          _vm.videoForm.needFaceTranslate
                            ? _c(
                                "el-select",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    placeholder: _vm.$t("tip.select"),
                                    clearable: "",
                                    filterable: "",
                                    "default-first-option": true,
                                  },
                                  model: {
                                    value: _vm.videoForm.sourceLanguage,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.videoForm,
                                        "sourceLanguage",
                                        $$v
                                      )
                                    },
                                    expression: "videoForm.sourceLanguage",
                                  },
                                },
                                _vm._l(
                                  _vm.dict.type.source_face_language,
                                  function (dict) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: dict.value,
                                        attrs: {
                                          label: dict.label,
                                          value: dict.value,
                                        },
                                      },
                                      [_c("span", [_vm._v(_vm._s(dict.label))])]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm.videoForm.needSubtitleTranslate
                            ? _c(
                                "el-select",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    placeholder: _vm.$t("tip.select"),
                                    clearable: "",
                                    filterable: "",
                                    "default-first-option": true,
                                  },
                                  model: {
                                    value: _vm.videoForm.sourceLanguage,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.videoForm,
                                        "sourceLanguage",
                                        $$v
                                      )
                                    },
                                    expression: "videoForm.sourceLanguage",
                                  },
                                },
                                _vm._l(
                                  _vm.dict.type.source_text_language,
                                  function (dict) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: dict.value,
                                        attrs: {
                                          label: dict.label,
                                          value: dict.value,
                                        },
                                      },
                                      [_c("span", [_vm._v(_vm._s(dict.label))])]
                                    )
                                  }
                                ),
                                1
                              )
                            : _c(
                                "el-select",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    placeholder: _vm.$t("tip.select"),
                                    clearable: "",
                                    filterable: "",
                                    "default-first-option": true,
                                  },
                                  model: {
                                    value: _vm.videoForm.sourceLanguage,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.videoForm,
                                        "sourceLanguage",
                                        $$v
                                      )
                                    },
                                    expression: "videoForm.sourceLanguage",
                                  },
                                },
                                _vm._l(
                                  _vm.dict.type.source_speech_language,
                                  function (dict) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: dict.value,
                                        attrs: {
                                          label: dict.label,
                                          value: dict.value,
                                        },
                                      },
                                      [_c("span", [_vm._v(_vm._s(dict.label))])]
                                    )
                                  }
                                ),
                                1
                              ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "videoTranslationPage.translationLanguage"
                            ),
                          },
                        },
                        [
                          _vm.videoForm.needFaceTranslate
                            ? _c(
                                "el-select",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    placeholder: _vm.$t("tip.select"),
                                    clearable: "",
                                    filterable: "",
                                    "default-first-option": true,
                                  },
                                  model: {
                                    value: _vm.videoForm.targetLanguage,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.videoForm,
                                        "targetLanguage",
                                        $$v
                                      )
                                    },
                                    expression: "videoForm.targetLanguage",
                                  },
                                },
                                _vm._l(
                                  _vm.dict.type.target_face_language,
                                  function (dict) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: dict.value,
                                        attrs: {
                                          label: dict.label,
                                          value: dict.value,
                                        },
                                      },
                                      [_c("span", [_vm._v(_vm._s(dict.label))])]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm.videoForm.needSpeechTranslate
                            ? _c(
                                "el-select",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    placeholder: _vm.$t("tip.select"),
                                    clearable: "",
                                    filterable: "",
                                    "default-first-option": true,
                                  },
                                  model: {
                                    value: _vm.videoForm.targetLanguage,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.videoForm,
                                        "targetLanguage",
                                        $$v
                                      )
                                    },
                                    expression: "videoForm.targetLanguage",
                                  },
                                },
                                _vm._l(
                                  _vm.dict.type.target_speech_language,
                                  function (dict) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: dict.value,
                                        attrs: {
                                          label: dict.label,
                                          value: dict.value,
                                        },
                                      },
                                      [_c("span", [_vm._v(_vm._s(dict.label))])]
                                    )
                                  }
                                ),
                                1
                              )
                            : _c(
                                "el-select",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    placeholder: _vm.$t("tip.select"),
                                    clearable: "",
                                    filterable: "",
                                    "default-first-option": true,
                                  },
                                  model: {
                                    value: _vm.videoForm.targetLanguage,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.videoForm,
                                        "targetLanguage",
                                        $$v
                                      )
                                    },
                                    expression: "videoForm.targetLanguage",
                                  },
                                },
                                _vm._l(
                                  _vm.dict.type.target_text_language,
                                  function (dict) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: dict.value,
                                        attrs: {
                                          label: dict.label,
                                          value: dict.value,
                                        },
                                      },
                                      [_c("span", [_vm._v(_vm._s(dict.label))])]
                                    )
                                  }
                                ),
                                1
                              ),
                        ],
                        1
                      ),
                      _vm.videoForm.needSubtitleTranslate
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "videoTranslationPage.keepSubtitle"
                                ),
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: true },
                                  model: {
                                    value: _vm.videoForm.bilingualSubtitle,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.videoForm,
                                        "bilingualSubtitle",
                                        $$v
                                      )
                                    },
                                    expression: "videoForm.bilingualSubtitle",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("videoTranslationPage.keep"))
                                  ),
                                ]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: false },
                                  model: {
                                    value: _vm.videoForm.bilingualSubtitle,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.videoForm,
                                        "bilingualSubtitle",
                                        $$v
                                      )
                                    },
                                    expression: "videoForm.bilingualSubtitle",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("videoTranslationPage.discard")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("videoTranslationPage.saveto"),
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "material" },
                              model: {
                                value: _vm.videoForm.saveTo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.videoForm, "saveTo", $$v)
                                },
                                expression: "videoForm.saveTo",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("videoTranslationPage.material"))
                              ),
                            ]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "product" },
                              model: {
                                value: _vm.videoForm.saveTo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.videoForm, "saveTo", $$v)
                                },
                                expression: "videoForm.saveTo",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("videoTranslationPage.work"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "gradient-button self-center",
                      staticStyle: { margin: "0 5px", width: "200px" },
                      attrs: { loading: _vm.submitLoading },
                      on: { click: _vm.makeVideo },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("videoTranslationPage.clickTranslation"))
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogPlay,
            width: "50%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPlay = $event
            },
          },
        },
        [
          _c("video", {
            ref: "videoPlayer",
            staticClass: "video-player",
            attrs: { controls: "", src: _vm.playUrl },
          }),
        ]
      ),
      _c("historyDrawer", {
        ref: "historyDrawer",
        attrs: { type: "videoTranslation", drawerHistory: _vm.drawerHistory },
        on: { closeHistory: _vm.closeHistory },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }