var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "aiSlicingDetail",
    },
    [
      _c("div", { staticClass: "actionBtn" }, [
        _c(
          "div",
          { staticClass: "leftBtn btn", on: { click: _vm.goToMaterial } },
          [
            _c("i", { staticClass: "iconfont icon-a-xialajiantoubeifen3" }),
            _vm._v(" " + _vm._s(_vm.$t("aiSlicing.goLibraryToView")) + " "),
          ]
        ),
        _c("div", { staticClass: "btn", on: { click: _vm.goBack } }, [
          _c("i", { staticClass: "iconfont icon-fanhui" }),
          _vm._v(" " + _vm._s(_vm.$t("aiSlicing.returningSlice")) + " "),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "detailContent" },
        _vm._l(_vm.detailList, function (item, index) {
          return _c("div", { key: index, staticClass: "detailItem" }, [
            _c("div", { staticClass: "headerTip" }, [
              _c("i", { staticClass: "iconfont icon-xing" }),
              _vm._v(" " + _vm._s(_vm.$t("aiSlicing.sliceInformation")) + " "),
            ]),
            _c(
              "div",
              { staticClass: "detailItemTitle" },
              [
                _c("div", { staticClass: "blue-text" }, [
                  _vm._v("#" + _vm._s(index + 1)),
                ]),
                !item.isEditTitle
                  ? _c("div", { staticClass: "detail-title" }, [
                      _vm._v(_vm._s(item.name)),
                    ])
                  : _c("el-input", {
                      ref: "titleInputRef-" + index,
                      refInFor: true,
                      on: {
                        blur: function ($event) {
                          return _vm.titleBlur(item, index)
                        },
                      },
                      model: {
                        value: item.editTitleVal,
                        callback: function ($$v) {
                          _vm.$set(item, "editTitleVal", $$v)
                        },
                        expression: "item.editTitleVal",
                      },
                    }),
                !item.isEditTitle
                  ? _c("i", {
                      staticClass: "iconfont icon-bianji2",
                      on: {
                        click: function ($event) {
                          return _vm.editTitle(item, index)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "detail-item-content" }, [
              _c("div", { staticClass: "left-num" }, [
                _c("span", { staticClass: "blue-num" }, [
                  _vm._v(_vm._s(index + 1)),
                ]),
                _c("span", { staticClass: "num" }, [
                  _vm._v("/" + _vm._s(_vm.detailList.length)),
                ]),
              ]),
              _c("div", { staticClass: "item-content" }, [
                _c("div", { staticClass: "video-box" }, [
                  _c("video", {
                    ref: "videoPlayer",
                    refInFor: true,
                    staticClass: "video-player",
                    attrs: { controls: "", src: item.url },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "item-describe" },
                  [
                    _c("div", { staticClass: "describe-header" }, [
                      _c("div", { staticClass: "describe-header-title" }, [
                        _c("div", { staticClass: "left-box" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("aiSlicing.fragmentDescription")) +
                            " "
                        ),
                      ]),
                      !item.isEditDescribe
                        ? _c("i", {
                            staticClass: "iconfont icon-bianji2",
                            on: {
                              click: function ($event) {
                                return _vm.editContent(item, index)
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "line" }),
                    !item.isEditDescribe
                      ? _c("div", { staticClass: "describe-content" }, [
                          _vm._v(" " + _vm._s(item.description) + " "),
                        ])
                      : _c("el-input", {
                          ref: "contentInputRef-" + index,
                          refInFor: true,
                          attrs: {
                            type: "textarea",
                            autosize: { maxRows: 12 },
                            resize: "none",
                            maxlength: "250",
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.contentBlur(item, index)
                            },
                          },
                          model: {
                            value: item.editDescribeVal,
                            callback: function ($$v) {
                              _vm.$set(item, "editDescribeVal", $$v)
                            },
                            expression: "item.editDescribeVal",
                          },
                        }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "right-tag" },
                [
                  _vm._l(item.tags, function (tag, tagIndex) {
                    return _c(
                      "div",
                      {
                        key: tagIndex + 1,
                        staticClass: "tag-box",
                        style: {
                          background: _vm.tagStyle[tagIndex].bg,
                          color: _vm.tagStyle[tagIndex].color,
                        },
                      },
                      [
                        _vm._v(" #" + _vm._s(tag) + " "),
                        item.isEditTags
                          ? _c("i", {
                              staticClass: "iconfont icon-dacha",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteTag(item, tagIndex)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    )
                  }),
                  _vm._l(item.selects, function (select, selectIndex) {
                    return _c(
                      "div",
                      { key: selectIndex, staticClass: "tag-select-container" },
                      [
                        item.isEditTags
                          ? _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("aiSlicing.pleaseSelect"),
                                },
                                model: {
                                  value: select.selectedTag,
                                  callback: function ($$v) {
                                    _vm.$set(select, "selectedTag", $$v)
                                  },
                                  expression: "select.selectedTag",
                                },
                              },
                              _vm._l(_vm.labelList, function (dict) {
                                return _c("el-option", {
                                  key: dict.id,
                                  attrs: {
                                    label: dict.label,
                                    value: dict.label,
                                  },
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                        item.isEditTags
                          ? _c("i", {
                              staticClass: "iconfont icon-dacha",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteSelect(item, selectIndex)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  item.isEditTags
                    ? _c("div", { staticClass: "action-btn" }, [
                        _c(
                          "div",
                          {
                            staticClass: "add-tag-btn",
                            on: {
                              click: function ($event) {
                                return _vm.addSelect(item, index)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "iconfont icon-tianjia" }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("aiSlicing.addTags")) + " "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "save-tag-btn",
                            on: {
                              click: function ($event) {
                                return _vm.saveTags(item, index)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont icon-sousuotiaojianbaocun",
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("aiSlicing.saveContent")) +
                                " "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  !item.isEditTags
                    ? _c(
                        "div",
                        {
                          staticClass: "edit-tag-btn",
                          on: {
                            click: function ($event) {
                              return _vm.editTags(item, index)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "iconfont icon-bianji2" }),
                          _vm._v(
                            " " + _vm._s(_vm.$t("aiSlicing.editTags")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }