var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unsubtitle" },
    [
      _c("div", { staticClass: "title-header" }, [
        _c("div", { staticClass: "title-header-text" }, [
          _vm._v(_vm._s(_vm.$t("unsubtitle.headerTitle"))),
        ]),
        _c(
          "div",
          { staticClass: "RightQuota" },
          [
            _vm._v(" " + _vm._s(_vm.$t("quota.quota")) + "："),
            _c("el-progress", {
              staticStyle: { margin: "0 16px" },
              attrs: {
                "stroke-width": 10,
                percentage: _vm.quota.usePublishCount || 0,
              },
            }),
            _vm._v(
              _vm._s(_vm.quota.usePublishCount || 0) +
                "/" +
                _vm._s(_vm.quota.publishCount || 100) +
                _vm._s(_vm.$t("videoTranslationPage.strip")) +
                " "
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "faceContent" }, [
        _c("div", { staticClass: "leftUpload" }, [
          _c(
            "div",
            {
              class: [
                "centerUpload",
                _vm.selectVideoList.length > 0 && "flexWarpBox",
              ],
            },
            [
              _vm._l(_vm.selectVideoList, function (material, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "aspect-ratio-box flex-column" },
                  [
                    _c("div", { staticClass: "material-container" }, [
                      _c("video", {
                        staticClass: "material-thumbnail",
                        attrs: { src: material.url },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "cricle",
                          on: {
                            click: function ($event) {
                              return _vm.playVideo(material.url)
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-caret-right" })]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "deleteIcon",
                          on: {
                            click: function ($event) {
                              return _vm.removeMaterial(index)
                            },
                          },
                        },
                        [_c("i", { staticClass: "iconfont icon-delete" })]
                      ),
                    ]),
                  ]
                )
              }),
              _vm.selectVideoList.length
                ? _c("uploadMaterialAndLoca", {
                    attrs: { fileType: _vm.fileType },
                    on: {
                      selectMaterial: _vm.selectMaterial,
                      showLocaMedia: _vm.showLocaMedia,
                    },
                  })
                : _c("ai-uploadFile", {
                    attrs: { fileType: _vm.fileType },
                    on: {
                      selectMaterial: _vm.selectMaterial,
                      showLocaMedia: _vm.showLocaMedia,
                    },
                  }),
            ],
            2
          ),
        ]),
        _c(
          "div",
          { staticClass: "rightForm flex-column" },
          [
            _c("div", { staticClass: "f-grow" }, [
              _c("div", { staticClass: "formTitle" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("unsubtitle.setting"))),
                ]),
                _c(
                  "div",
                  { staticClass: "history", on: { click: _vm.openHistory } },
                  [
                    _c("i", { staticClass: "iconfont icon-gongzuotai2" }),
                    _vm._v(_vm._s(_vm.$t("videoTranslationPage.history"))),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "addFaceBox" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("unsubtitle.removeSubtitles")) + ":"),
                  ]),
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.checkboxGroup,
                        callback: function ($$v) {
                          _vm.checkboxGroup = $$v
                        },
                        expression: "checkboxGroup",
                      },
                    },
                    [
                      _c("el-checkbox", {
                        attrs: {
                          label: _vm.$t("unsubtitle.fullScreen"),
                          border: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "selectSaveType" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("faceSwap.storageLocation")) + ":"),
                  ]),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.saveTo,
                        callback: function ($$v) {
                          _vm.saveTo = $$v
                        },
                        expression: "saveTo",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "material" } }, [
                        _vm._v(_vm._s(_vm.$t("videoTranslationPage.material"))),
                      ]),
                      _c("el-radio", { attrs: { label: "product" } }, [
                        _vm._v(_vm._s(_vm.$t("videoTranslationPage.work"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c(
              "el-button",
              {
                staticClass: "gradient-button self-center",
                staticStyle: { margin: "25px 0", width: "200px" },
                attrs: { loading: _vm.submitLoading },
                on: { click: _vm.submitUnsubtitle },
              },
              [_vm._v(_vm._s(_vm.$t("unsubtitle.clickSubtitleRemoval")))]
            ),
          ],
          1
        ),
      ]),
      _vm.dialogPlay
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.dialogPlay, width: "50%" },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogPlay = $event
                },
              },
            },
            [
              _c("video", {
                ref: "videoPlayer",
                staticClass: "video-player",
                attrs: { controls: "", src: _vm.playUrl },
              }),
            ]
          )
        : _vm._e(),
      _vm.drawerHistory
        ? _c("historyDrawer", {
            attrs: { type: "eraseSubtitle", drawerHistory: _vm.drawerHistory },
            on: { closeHistory: _vm.closeHistory },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }