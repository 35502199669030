<!-- 去字幕 -->
<template>
  <div class="unsubtitle">
    <div class="title-header">
      <div class="title-header-text">{{ $t('unsubtitle.headerTitle') }}</div>
      <div class="RightQuota">
        {{$t('quota.quota')}}：<el-progress
          style="margin: 0 16px;"
          :stroke-width="10"
          :percentage="quota.usePublishCount || 0"
        ></el-progress>{{ quota.usePublishCount || 0}}/{{ quota.publishCount || 100}}{{ $t('videoTranslationPage.strip') }}
      </div>
    </div>
    <div class="faceContent">
      <div class="leftUpload">
        <div :class="['centerUpload', selectVideoList.length > 0 && 'flexWarpBox']">
          <div
            v-for="(material, index) in selectVideoList"
            :key="index"
            class="aspect-ratio-box flex-column"
          >
            <div class="material-container">
              <video
                :src="material.url"
                class="material-thumbnail"
              ></video>
              <div
                class="cricle"
                @click="playVideo(material.url)"
              >
                <i class="el-icon-caret-right"></i>
              </div>
              <div
                class="deleteIcon"
                @click="removeMaterial(index)"
              >
                <i class="iconfont icon-delete"></i>
              </div>
            </div>
          </div>
          <uploadMaterialAndLoca
            v-if="selectVideoList.length"
            :fileType="fileType"
            @selectMaterial="selectMaterial"
            @showLocaMedia="showLocaMedia"
          />
          <ai-uploadFile
            v-else
            :fileType="fileType"
            @selectMaterial="selectMaterial"
            @showLocaMedia="showLocaMedia"
          />
          <!-- <ai-uploadFile
            v-if="!selectVideoList.length"
            :fileType="fileType"
            @selectMaterial="selectMaterial"
            @showLocaMedia="showLocaMedia"
          /> -->
        </div>
      </div>
      <div class="rightForm flex-column">
        <div class="f-grow">
          <div class="formTitle">
            <div class="title">{{ $t('unsubtitle.setting') }}</div>
            <div
              class="history"
              @click="openHistory"
            ><i class="iconfont icon-gongzuotai2"></i>{{ $t('videoTranslationPage.history') }}</div>
          </div>
          <!-- <div class="vt-tip">
              <i class="iconfont icon-zhushi"></i>
              可上传多个正面人脸视频，批量生成换脸视频，脸型图片需为正面人脸
          </div> -->
          <div class="addFaceBox">
            <div class="title">{{$t('unsubtitle.removeSubtitles')}}:</div>
            <el-checkbox-group
              v-model="checkboxGroup"
              disabled
            >
              <el-checkbox
                :label="$t('unsubtitle.fullScreen')"
                border
              ></el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="selectSaveType">
            <div class="title">{{$t('faceSwap.storageLocation')}}:</div>
            <el-radio-group v-model="saveTo">
              <el-radio label="material">{{ $t('videoTranslationPage.material') }}</el-radio>
              <el-radio label="product">{{ $t('videoTranslationPage.work') }}</el-radio>
            </el-radio-group>
          </div>
        </div>

        <el-button
          :loading="submitLoading"
          class="gradient-button self-center"
          @click="submitUnsubtitle"
          style="margin: 25px 0;width:200px"
        >{{ $t('unsubtitle.clickSubtitleRemoval') }}</el-button>
      </div>
    </div>
    <el-dialog
      v-if="dialogPlay"
      :visible.sync="dialogPlay"
      width="50%"
    >
      <video
        controls
        :src="playUrl"
        class="video-player"
        ref="videoPlayer"
      ></video>
    </el-dialog>
    <historyDrawer
      v-if="drawerHistory"
      type="eraseSubtitle"
      :drawerHistory="drawerHistory"
      @closeHistory="closeHistory"
    />
  </div>
</template>

<script>
import uploadMaterialAndLoca from "./components/uploadMaterialAndLoca.vue";
import aiUploadFile from "./components/aiUploadFile.vue";
import historyDrawer from "./components/historyDrawer.vue";
import { eraseSubtitle } from "../../api/ai/aiFacewarp";
export default {
  components: { uploadMaterialAndLoca, aiUploadFile, historyDrawer },
  data() {
    return {
      quota: {
        usePublishCount: 0,
        publishCount: 100,
      }, //配额
      drawer: false, //素材选择的抽屉
      saveTo: "material",
      selectVideoList: [],
      playUrl: "",
      dialogPlay: false,
      fileType: ["mp4", "avi", "mkv", "mov"],
      submitLoading: false,
      drawerHistory: false,
      checkboxGroup: ["全屏"],
    };
  },
  methods: {
    filterSeletVideoList() {
      return this.selectVideoList.map((item) => {
        return { url: item.url, videoName: item.name };
      });
    },
    closeHistory() {
      this.drawerHistory = false;
    },
    openHistory() {
      this.drawerHistory = true;
    },
    async submitUnsubtitle() {
      if (this.filterSeletVideoList().length && this.saveTo) {
        try {
          this.submitLoading = true;
          await eraseSubtitle({
            videos: this.filterSeletVideoList(),
            // videoName: this.selectVideoList[0].name,
          });
          this.selectVideoList = [];
          this.submitLoading = false;
          this.$message({
            message: this.$t("faceSwap.successTip"),
            type: "success",
          });
          // console.log(this.filterSeletVideoList(), this.saveTo, '表单');
        } catch (e) {
          this.submitLoading = false;
          console.log(e);
        }
      } else {
        this.$message({
          message: this.$t("faceSwap.warningTip"),
          type: "warning",
        });
      }
    },
    removeMaterial(index) {
      this.selectVideoList.splice(index, 1);
    },
    showLocaMedia(items) {
      items.forEach((item) => {
        this.selectVideoList.push(item);
      });
    },
    selectMaterial(items) {
      // if(items.length === 1) {
      //   this.selectVideoList.push(items[0]);
      // } else {
      //   this.$message({ message: '请选择单个素材', type: 'warning' })
      // }
      items.forEach((item) => {
        this.selectVideoList.push(item);
      });
    },
    playVideo(url) {
      this.playUrl = url;
      this.dialogPlay = true;
      // 播放视频时获取视频元素
      this.$nextTick(() => {
        this.videoElement = this.$refs.videoPlayer;
        this.videoElement.play();
      });
    },
    chooseMaterial(num) {
      this.drawer = true;
      let type;
      if (num === 2) {
        type = 2;
      } else if (num === 3 || num === 4 || num === 5) {
        type = 3;
      }
      this.$nextTick().then((rs) => {
        this.$refs.materialLibrary.init(type);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.unsubtitle {
  padding: 15px;

  .RightQuota {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 22px;
    text-align: right;
    font-style: normal;
    display: flex;
    align-items: center;
    .el-progress__text {
      display: none;
    }
    .el-progress-bar {
      padding-right: 0;
      display: inline-block;
      vertical-align: middle;
      width: 160px;
      margin-right: 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    .el-progress-bar__inner {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background: linear-gradient(90deg, #0089ff 0%, #6e16d1 100%);
      text-align: right;
      border-radius: 100px;
      line-height: 1;
      white-space: nowrap;
      -webkit-transition: width 0.6s ease;
      transition: width 0.6s ease;
    }
  }
  .faceContent {
    display: flex;
    height: calc(100vh - 200px);
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    padding: 24px;
    .leftUpload {
      flex: 1;
      height: 100%;
      overflow-y: auto;
      background: #f6f8fa;
      border-radius: 12px;
      border: 2px dashed rgba(179, 179, 179, 0.38);
      .centerUpload {
        height: 100%;
      }
      .flexWarpBox {
        height: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 16px;
        .aspect-ratio-box {
          width: 200px;
          height: 366px;
          border: 1px solid #e5e7eb;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 12px;
          margin-bottom: 5px; /* 调整间距 */
          position: relative;
          .material-container {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            .material-thumbnail {
              width: 100%;
              height: 100%; /* 根据需要调整高度 */
              object-fit: cover;
              border-radius: 12px;
            }
            .cricle {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              display: flex;
              justify-content: center;
              align-items: center;
              width: 54px;
              height: 54px;
              border-radius: 50%;
              background: rgba(255, 255, 255, 0.5);
              cursor: pointer;
              .el-icon-caret-right {
                color: #fff;
                font-size: 35px;
              }
            }
            .deleteIcon {
              position: absolute;
              top: 8px;
              right: 8px;
              width: 28px;
              height: 28px;
              background: #000000;
              border-radius: 8px;
              opacity: 0.5;
              cursor: pointer;
              justify-content: center;
              display: flex;
              align-items: center;
              .icon-delete {
                color: #fff;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .rightForm {
      width: 35%;
      height: 100%;
      padding: 0 24px;
      position: relative;
      overflow-y: auto;
      .formTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-family: PingFang-SC, PingFang-SC;
          font-weight: bold;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.9);
          line-height: 22px;
          text-align: left;
          font-style: normal;
        }
        .history {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #0156ff;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          cursor: pointer;
          display: flex;
          align-items: center;
          .icon-gongzuotai2 {
            margin-right: 4px;
          }
        }
      }
      .vt-tip {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.4);
        line-height: 22px;
        text-align: left;
        font-style: normal;
        margin-top: 16px;
        display: flex;
        .icon-zhushi {
          font-size: 14px;
          margin-right: 4px;
        }
      }
      .addFaceBox {
        margin-top: 34px;
        .title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.9);
          line-height: 20px;
          text-align: left;
          font-style: normal;
          margin-bottom: 8px;
        }
        // .uploadFace {
        //   display: flex;
        // }
        // .facePicBox {
        //   width: 180px;
        //   height: 293px;
        //   border-radius: 12px;
        //   overflow: hidden;
        //   margin-left: 24px;
        //   position: relative;
        //   img {
        //     width: 180px;
        //     height: 293px;
        //     object-fit: cover;
        //   }
        //   .deleteIcon {
        //     position: absolute;
        //     top: 8px;
        //     right: 8px;
        //     width: 28px;
        //     height: 28px;
        //     background: #000000;
        //     border-radius: 8px;
        //     opacity: 0.5;
        //     cursor: pointer;
        //     justify-content: center;
        //     display: flex;
        //     align-items: center;
        //     .icon-delete {
        //       color: #fff;
        //       font-size: 16px;
        //     }
        //   }
        // }
      }
      .selectSaveType {
        margin-top: 34px;
        .title {
          margin-bottom: 18px;
        }
      }
    }
  }
}
</style>
